import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change as changeFieldValue, touch, getFormValues, getFormSubmitErrors } from 'redux-form'
import {
  resetYandexCampaignsState,
  fetchYandexCampaigns,
  getYandexCampaigns,
  isFetching as isCampaignsFetching,
} from '../../../../stores/yandexCampaigns'
import Campaigns from './components/Campaigns'

const reduxFormSelector = getFormValues('newTask')

const mapStateToProps = (state, { meta }) => {
  const errors = getFormSubmitErrors('newTask')(state)
  const formValues = reduxFormSelector(state) || {}
  const campaigns = getYandexCampaigns(state)

  return {
    campaigns: campaigns.filter((item) => !item.archived),
    selectedCampaigns: formValues.campaigns || [],
    isFetching: isCampaignsFetching(state),
    error: (errors && errors.campaigns) || (meta.touched ? meta.error : null),
  }
}

class CampaignsContainer extends Component {
  componentDidMount() {
    this.fetchCampaigns()
  }

  componentWillUnmount() {
    this.props.resetYandexCampaignsState()
  }

  fetchCampaigns = () => {
    this.props.fetchYandexCampaigns(this.props.projectId)
  }

  changeCampaignsFieldValue = (newValue) => {
    this.props.touch('newTask', 'campaigns')
    return this.props.changeFieldValue('newTask', 'campaigns', newValue)
  }

  render() {
    const { campaigns, selectedCampaigns, isFetching, error, projectId } = this.props

    return (
      <Campaigns
        campaigns={campaigns}
        selectedCampaigns={selectedCampaigns}
        isFetching={isFetching}
        onChange={this.changeCampaignsFieldValue}
        refreshCampaigns={this.fetchCampaigns}
        error={error}
        projectId={projectId}
      />
    )
  }
}

CampaignsContainer.defaultProps = {
  error: null,
}

CampaignsContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCampaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isFetching: PropTypes.bool.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  resetYandexCampaignsState: PropTypes.func.isRequired,
  fetchYandexCampaigns: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  changeFieldValue,
  touch,
  resetYandexCampaignsState,
  fetchYandexCampaigns,
})(CampaignsContainer)
