import { createGlobalStyle } from 'styled-components/macro'
import 'semantic-ui-css/semantic.min.css'
import 'react-virtualized/styles.css'
import { TEXT_BLACK } from './variables'

/* eslint-disable */

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:400&subset=cyrillic');

  body {
    color: ${TEXT_BLACK};
    background: linear-gradient(#f7f7f7, #fbfbfb);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-y: scroll;
  }

  button {
    border: unset;
    outline: unset;
    background: unset;
  }

  .no-decoration {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9) !important;

    &:hover {
      color: #fff !important;
    }
  }

  .container-relative {
    position: relative;
  }

  .no-opacity {
    opacity: 1 !important;
  }

  .ui {
    font-family: 'Roboto', sans-serif !important;

    .header,
    .heading,
    .title,
    .active {
      font-family: 'Roboto', sans-serif !important;
    }
  }

  .monospace {
    font-family: 'Roboto Mono', monospace;
  }
`
