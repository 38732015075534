import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import Tooltip from '../../../../components/Tooltip'

const LayoutIcon = ({ name, withWarning }) => {
  if (withWarning) {
    const tooltipId = `${name.replace(' ', '-')}-tooltip`

    return (
      <>
        <Icon.Group
          data-for={tooltipId}
          data-tip="Отчет содержит слишком много данных.<br> При переключении на этот вид страница может долгое время не отвечать."
          data-multiline
        >
          <Icon name={name} />
          <Icon corner="top right" name="circle" color="orange" />
        </Icon.Group>
        <Tooltip id={tooltipId} />
      </>
    )
  }

  return <Icon name={name} />
}

LayoutIcon.defaultProps = {
  withWarning: false,
}

LayoutIcon.propTypes = {
  name: PropTypes.string.isRequired,
  withWarning: PropTypes.bool,
}

export default LayoutIcon
