import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearSubmitErrors, setSubmitSucceeded } from 'redux-form'
import NewTaskPage from './components/NewTaskPage'
import { createTask, isCreating } from '../../stores/tasks'
import {
  getCampaignIds,
  createChunks,
  makeHandleResponse,
  splitByLineBreak,
} from '../../utils/taskFormUtils'
import { CAMPAIGNS_CHUNK_SIZE } from '../../constants/new-task-form'

const ERROR_MAPPING = [
  'campaigns',
  /^campaigns\[\d+]$/,
  'addKeywords',
  /^addKeywords\[\d+]$/,
  'subtractKeywords',
  /^subtractKeywords\[\d+]$/,
  'impressionsThreshold',
]

const handleResponse = makeHandleResponse('new_task_form', ERROR_MAPPING)

const mapStateToProps = (state) => ({
  isCreating: isCreating(state),
})

class NewTaskPageContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      batchMode: true,
      batchError: false,
    }
    // for test environment
    this.handleResponse = handleResponse
  }

  changeMode = (e, { checked }) => this.setState({ batchMode: checked })

  handleSubmit = (formData) => {
    const {
      history,
      match: {
        params: { projectId },
      },
    } = this.props
    const { unsavedChunks, totalChunks, batchMode } = this.state

    if (unsavedChunks && unsavedChunks.length !== totalChunks) {
      return this.handleSubmitContinue()
    }

    const data = {
      campaigns: getCampaignIds(formData.campaigns),
      adGroups: {
        enabled: false,
      },
      optimization: {
        enabled: !!formData.optimization,
        checkCrossingWithKeywords:
          (formData.optimization && formData.checkCrossingWithKeywords) || false,
      },
    }
    const addKeywords = splitByLineBreak(formData.addKeywords)
    const subtractKeywords = splitByLineBreak(formData.subtractKeywords)

    if (formData.name) {
      data.name = formData.name
    }

    if (formData.impressionsThreshold) {
      data.impressionsThreshold = Number(formData.impressionsThreshold)
    }

    if (addKeywords.length) {
      data.addKeywords = addKeywords
    }

    if (subtractKeywords.length) {
      data.subtractKeywords = subtractKeywords
    }

    const removeDuplicates = !!formData.removeDuplicates

    if (batchMode) {
      const unsavedChunks = createChunks(data, CAMPAIGNS_CHUNK_SIZE)
      this.setState({
        totalChunks: unsavedChunks.length,
        unsavedChunks,
      })

      return this.createBatchTasks(projectId, unsavedChunks, removeDuplicates)
    }

    return this.props
      .createTask(projectId, data, removeDuplicates, (taskId) =>
        history.push(`/${projectId}/${taskId}`)
      )
      .then(this.handleResponse)
  }

  handleSubmitContinue = () => {
    this.setState({ batchError: false })

    this.props.clearSubmitErrors('newTask')
    this.props.setSubmitSucceeded('newTask')

    return this.createBatchTasks(this.props.match.params.projectId, this.state.unsavedChunks)
  }

  createBatchTasks = async (projectId, unsavedChunks, removeDuplicates) => {
    try {
      for (let i = 0; i < unsavedChunks.length; i += 1) {
        const response = await this.props.createTask(projectId, unsavedChunks[i], removeDuplicates)
        this.handleResponse(response)

        this.setState({
          unsavedChunks: unsavedChunks.slice(i + 1),
        })
      }
    } catch (err) {
      this.setState({ batchError: true })
      throw err
    }

    this.props.history.push(`/${projectId}`)
  }

  render() {
    const {
      match: {
        params: { projectId },
      },
      isCreating,
    } = this.props
    const { batchError, totalChunks, unsavedChunks } = this.state

    return (
      <NewTaskPage
        projectId={projectId}
        batchMode={this.state.batchMode}
        batchError={batchError}
        batchCurrent={totalChunks - (unsavedChunks || []).length}
        batchTotal={totalChunks}
        handleSubmit={this.handleSubmit}
        changeMode={this.changeMode}
        acceptChanges={unsavedChunks ? unsavedChunks.length === totalChunks : true}
        isCreating={isCreating}
      />
    )
  }
}

NewTaskPageContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  createTask: PropTypes.func.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  setSubmitSucceeded: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {
  clearSubmitErrors,
  setSubmitSucceeded,
  createTask,
})(NewTaskPageContainer)
