import camelCase from 'lodash/camelCase'

const pointerToFormPath = (str) => {
  if (str) {
    const camelizedStr = str
      .split('.')
      .map((word) => {
        const idx = word.match(/\[([0-9]+)\]$/g)
        if (idx) {
          return camelCase(word.replace(idx, '')) + idx
        }
        return camelCase(word)
      })
      .join('.')
    return camelizedStr.substring(5)
  }

  return null
}

export default pointerToFormPath
