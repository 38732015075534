import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Icon, Rail, Table } from 'semantic-ui-react'
import { ContextRequestError } from 'pannier'
import Tooltip from '../../components/Tooltip'
import CampaignsFetchFailure from '../../components/CampaignsFetchFailure'
import { FETCH_LIST_FAILURE as FETCH_YANDEX_CAMPAIGNS_LIST_FAILURE } from '../../../stores/yandexCampaigns'
import * as adjStatus from '../../../constants/campaign-adj-statuses'
import { COMPLETED, SYNC_FAILED, SYNCING } from '../../../constants/task-statuses'
import * as syncStatuses from '../../../constants/sync-statuses'

const RELEVANT_TASK_STATUSES = [SYNCING, SYNC_FAILED, COMPLETED]
const SYNC_ERROR_STATUSES = [syncStatuses.CONFLICT, syncStatuses.ERROR]

const hasErrors = (adGroups) =>
  (adGroups || []).some((group) => SYNC_ERROR_STATUSES.includes(group.syncStatus))

const SyncStatus = ({ status }) => (
  <span data-for="campaign-tooltip" data-tip={syncStatuses.getDescription(status)}>
    {syncStatuses.translate(status)}
  </span>
)

const AdjustStatus = ({ status }) => (
  <span data-for="campaign-tooltip" data-tip={adjStatus.getDescription(status)}>
    {adjStatus.translate(status)}
  </span>
)

AdjustStatus.propTypes = { status: PropTypes.string.isRequired }
SyncStatus.propTypes = { status: PropTypes.string.isRequired }

const CampaignItem = ({ id, adjStatus, syncStatus, name, archived, adGroups, taskStatus }) => {
  const [expanded, setExpand] = React.useState(false)

  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>
          {adGroups && adGroups.length > 0 && (
            <Icon
              name={expanded ? 'angle down' : 'angle right'}
              link
              onClick={() => setExpand(!expanded)}
            />
          )}
        </Table.Cell>
        <Table.Cell disabled={archived}>{id}</Table.Cell>
        <Table.Cell disabled={archived}>
          <div className="campaign-info">
            <div className="campaign-info__name">
              {name} {archived && <Icon name="archive" />}
            </div>
            {hasErrors(adGroups) && (
              <div className="campaign-info__icon">
                <Icon
                  name="exclamation triangle"
                  color="yellow"
                  data-for="campaign-tooltip"
                  data-tip="Есть ошибки на уровне групп объявлений"
                />
              </div>
            )}
          </div>
        </Table.Cell>
        <Table.Cell singleLine disabled={archived}>
          {adjStatus && <AdjustStatus status={adjStatus} />}
        </Table.Cell>
        <Table.Cell singleLine disabled={archived}>
          {RELEVANT_TASK_STATUSES.includes(taskStatus) && <SyncStatus status={syncStatus} />}
        </Table.Cell>
      </Table.Row>
      {adGroups && adGroups.length > 0 && (
        <Table.Row
          key={`${id}-groups`}
          className={`groups-container${expanded ? ' groups-container__expanded' : ''}`}
          disabled={archived}
        >
          <Table.Cell />
          <Table.Cell colSpan={4}>
            <Table>
              <Table.Body>
                {adGroups.map(({ id, syncStatus }) => (
                  <Table.Row key={id}>
                    <Table.Cell>Группа № {id}</Table.Cell>
                    <Table.Cell width={2} singleLine>
                      <SyncStatus status={syncStatus} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      )}
    </React.Fragment>
  )
}

CampaignItem.defaultProps = {
  name: null,
  archived: false,
  adjStatus: null,
  syncStatus: null,
  adGroups: [],
}

CampaignItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  archived: PropTypes.bool,
  adjStatus: PropTypes.string,
  syncStatus: PropTypes.string,
  taskStatus: PropTypes.string.isRequired,
  adGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      syncStatus: PropTypes.string.isRequired,
    })
  ),
}

const CampaignsList = ({ className, campaigns, taskStatus, projectId }) => (
  <div className={className}>
    <Rail position="left">
      <ContextRequestError types={[FETCH_YANDEX_CAMPAIGNS_LIST_FAILURE]}>
        <CampaignsFetchFailure projectId={projectId} className="icon-campaigns-failure" />
      </ContextRequestError>
    </Rail>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={1}>ID</Table.HeaderCell>
          <Table.HeaderCell>Название кампании</Table.HeaderCell>
          <Table.HeaderCell width={2}>Корректировка</Table.HeaderCell>
          <Table.HeaderCell width={2}>Синхронизация</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {campaigns.map((item) => (
          <CampaignItem key={item.id} {...item} taskStatus={taskStatus} />
        ))}
      </Table.Body>
    </Table>
    <Tooltip id="campaign-tooltip" />
  </div>
)

CampaignsList.propTypes = {
  className: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      archived: PropTypes.bool,
      syncStatus: PropTypes.string,
    })
  ).isRequired,
  taskStatus: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
}

const StyledCampaignsList = styled(CampaignsList)`
  .groups-container {
    display: none;

    &__expanded {
      display: table-row;
    }
  }

  .campaign-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__icon {
      padding-left: 0.75em;
    }
  }
`

StyledCampaignsList.WrappedComponent = CampaignsList

export default StyledCampaignsList
