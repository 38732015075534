import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Header } from 'semantic-ui-react'
import NewTaskForm from './NewTaskForm'

const NewTaskPageWrap = styled.div`
  margin: 0 auto;
  padding-top: 5vh;
  padding-bottom: 5vh;
  max-width: 60%;

  @media (max-width: 1250px) {
    max-width: 80%;
  }
`

const NewTaskPage = ({
  projectId,
  batchMode,
  batchError,
  batchCurrent,
  batchTotal,
  handleSubmit,
  changeMode,
  acceptChanges,
  isCreating,
}) => (
  <NewTaskPageWrap>
    <Header as="h3" textAlign="center" content="Добавление новой задачи" />
    <NewTaskForm
      onSubmit={handleSubmit}
      projectId={projectId}
      batchMode={batchMode}
      batchError={batchError}
      batchCurrent={batchCurrent}
      batchTotal={batchTotal}
      changeMode={changeMode}
      acceptChanges={acceptChanges}
      isCreating={isCreating}
    />
  </NewTaskPageWrap>
)

NewTaskPage.defaultProps = {
  batchCurrent: null,
  batchTotal: null,
}

NewTaskPage.propTypes = {
  projectId: PropTypes.string.isRequired,
  batchMode: PropTypes.bool.isRequired,
  batchError: PropTypes.bool.isRequired,
  batchCurrent: PropTypes.number,
  batchTotal: PropTypes.number,
  changeMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  acceptChanges: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

export default NewTaskPage
