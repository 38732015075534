import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import getDisplayName from './getDisplayName'

const withInfiniteScroll = (WrappedComponent) => {
  class Wrapper extends Component {
    constructor(props) {
      super(props)

      this.onScroll = debounce(this.onScroll, 300)
    }

    componentDidMount() {
      if (this.props.shouldFetch) {
        window.addEventListener('scroll', this.onScroll)
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.shouldFetch && !this.props.shouldFetch) {
        window.removeEventListener('scroll', this.onScroll)
      } else if (!prevProps.shouldFetch && this.props.shouldFetch) {
        window.addEventListener('scroll', this.onScroll)
      }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
      if (window.innerHeight + window.scrollY > document.body.scrollHeight - 200) {
        this.props.fetchData()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Wrapper.displayName = `InfiniteScroll(${getDisplayName(WrappedComponent)})`

  Wrapper.propTypes = {
    fetchData: PropTypes.func.isRequired,
    shouldFetch: PropTypes.bool.isRequired,
  }

  return Wrapper
}

export default withInfiniteScroll
