export const PENDING = 'pending'
export const PREPARED = 'prepared'
export const ADJUSTED = 'adjusted'
export const FAILED = 'failed'

export const getDescription = (status) => {
  switch (status) {
    case PENDING:
      return 'Корректировка списка минус-фраз ещё не производилась.'
    case PREPARED:
      return 'Получены данные из Яндекс.Директа.'
    case ADJUSTED:
      return 'Корректировка списка минус-фраз выполнена.'
    case FAILED:
      return 'Возникла ошибка при корректировке списка минус-фраз.'
    // no default
  }

  return status
}

export const translate = (status) => {
  switch (status) {
    case PENDING:
      return ''
    case PREPARED:
      return 'выполняется'
    case ADJUSTED:
      return 'выполнено'
    case FAILED:
      return 'ошибка'
    // no default
  }

  return status
}
