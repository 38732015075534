import last from 'lodash/last'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'

const mergeByProp = (arr, prop, propToMerge, options) => {
  const sortFunc =
    options && options.sortFunc
      ? options.sortFunc
      : (a, b) => a[prop].toString().localeCompare(b[prop].toString())
  const sortedArr = arr.sort(sortFunc)

  return sortedArr.reduce((acc, item) => {
    const lastItem = last(acc) || {}
    const conditionResult = options && options.condition ? options.condition(lastItem, item) : true

    if (isEqual(lastItem[prop], item[prop]) && conditionResult) {
      const newProp = Array.isArray(lastItem[propToMerge])
        ? [...lastItem[propToMerge], item[propToMerge]]
        : [lastItem[propToMerge], item[propToMerge]]

      acc[acc.length - 1] = { ...lastItem, [propToMerge]: newProp }
    } else {
      acc.push(
        Array.isArray(item[propToMerge])
          ? item
          : {
              ...item,
              [propToMerge]: [item[propToMerge]],
            }
      )
    }

    return acc
  }, [])
}

const listHash = (list) =>
  list.items.map((item) => item.keyword + (item.matches || '').toString()).toString()

const statusMergeSortFunc = (a, b) => listHash(a).localeCompare(listHash(b))

const transformKeywords = (keywords) =>
  keywords.map(({ campaignId, adjStatus, keyword, matches }) => {
    const items = { keyword }

    if (matches && matches.length) {
      items.matches = matches
    }

    return {
      campaignId,
      adjStatus,
      items,
    }
  })

const groupReportItems = (keywords) => {
  if (!keywords) {
    return []
  }

  const transformedNegativeKeywords = transformKeywords(keywords)
  const itemsGroupedByCampaign = Object.values(groupBy(transformedNegativeKeywords, 'campaignId'))
  const campaignsWithMergedKeywords = flatten(
    itemsGroupedByCampaign.map((group) => mergeByProp(group, 'adjStatus', 'items'))
  )

  const itemsGroupedByStatus = Object.values(groupBy(campaignsWithMergedKeywords, 'adjStatus'))
  const sortedItemsGroupedByStatus = itemsGroupedByStatus.map((group) =>
    group.map((groupItem) => ({
      ...groupItem,
      items: Array.isArray(groupItem.items)
        ? groupItem.items.sort((a, b) => a.keyword.localeCompare(b.keyword))
        : [groupItem.items],
    }))
  )

  return flatten(
    sortedItemsGroupedByStatus.map((group) =>
      mergeByProp(group, 'items', 'campaignId', {
        sortFunc: statusMergeSortFunc,
      })
    )
  )
}

export default groupReportItems
