import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'

/* eslint-disable react/no-array-index-key */
const WordsList = ({ words }) => (
  <List>
    {words.map((word, idx) => (
      <List.Item key={idx}>{word}</List.Item>
    ))}
  </List>
)
/* eslint-enable react/no-array-index-key */

WordsList.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default WordsList
