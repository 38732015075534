import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon, Popup } from 'semantic-ui-react'
import { InlineButton } from 'pannier'
import { fetchYandexCampaigns } from '../../stores/yandexCampaigns'

class CampaignsFetchFailure extends Component {
  state = { isOpen: false }

  openPopup = () => this.setState({ isOpen: true })

  closePopup = () => this.setState({ isOpen: false })

  fetchCampaigns = () => {
    this.props.fetchYandexCampaigns(this.props.projectId)
    this.closePopup()
  }

  render() {
    const { isOpen } = this.state

    return (
      <Fragment>
        <Popup
          trigger={
            <Icon
              name="exclamation circle"
              color="red"
              size="large"
              link
              className={this.props.className}
            />
          }
          content={
            <Fragment>
              <p>Не удалось получить данные по кампаниям из Яндекс.Директа.</p>
              <p>
                <InlineButton onClick={this.fetchCampaigns}>Попробовать</InlineButton> загрузить
                список кампаний ещё раз.
              </p>
            </Fragment>
          }
          open={isOpen}
          onOpen={this.openPopup}
          onClose={this.closePopup}
          on="click"
        />
      </Fragment>
    )
  }
}

CampaignsFetchFailure.defaultProps = {
  className: null,
}

CampaignsFetchFailure.propTypes = {
  className: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  fetchYandexCampaigns: PropTypes.func.isRequired,
}

export default connect(null, { fetchYandexCampaigns })(CampaignsFetchFailure)
