export const ALL = 'all'
export const CAMPAIGN = 'campaign'
export const AD_GROUP = 'ad-group'

export const translate = (value) => {
  switch (value) {
    case ALL:
      return 'Все изменения'
    case CAMPAIGN:
      return 'Только изменения на уровне списка РК'
    case AD_GROUP:
      return 'Только изменения на уровне групп объявлений'
    // no default
  }

  throw new Error(`Unknown filter: ${value}`)
}
