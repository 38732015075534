import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Header, Grid, Icon } from 'semantic-ui-react'
import WordsList from './WordsList'

const StyledOption = styled.div`
  display: flex;
`

const FlagOption = ({ value, title }) => (
  <StyledOption>
    <Icon
      name={`toggle ${value ? 'on' : 'off'}`}
      color={value ? 'green' : undefined}
      size="large"
    />
    <span>{title}</span>
  </StyledOption>
)

FlagOption.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

const TaskSettings = ({ task }) => {
  const { addKeywords, subtractKeywords, adGroups, optimization, impressionsThreshold } = task

  return (
    <Grid>
      {adGroups && (
        <Grid.Row>
          <Grid.Column width={16}>
            <FlagOption
              title="Использовать списки минус-фраз на уровне групп объявлений"
              value={adGroups.enabled}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={16}>
          <FlagOption title="Оптимизация списка единых минус-фраз" value={optimization.enabled} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <FlagOption
            title="Проверка пересечения с ключевыми фразами"
            value={optimization.checkCrossingWithKeywords}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <strong>Минимальная частотность минус-фразы:</strong> {impressionsThreshold || 0}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h4">Фразы для добавления в единые минус-фразы</Header>
          {addKeywords && <WordsList words={addKeywords} />}
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h4">Фразы для удаления из единых минус-фраз</Header>
          {subtractKeywords && <WordsList words={subtractKeywords} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

TaskSettings.propTypes = {
  task: PropTypes.shape({
    addKeywords: PropTypes.arrayOf(PropTypes.string),
    subtractKeywords: PropTypes.arrayOf(PropTypes.string),
    adGroups: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
    }).isRequired,
    optimization: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      checkCrossingWithKeywords: PropTypes.bool.isRequired,
    }).isRequired,
    impressionsThreshold: PropTypes.number,
  }).isRequired,
}

export default TaskSettings
