import omit from 'lodash/omit'
import uniq from 'lodash/uniq'

const updateEntity = (state, id, data) => ({
  ...state,
  [id]: {
    ...state[id],
    ...data,
  },
})

export const resetIds = (state, action) => (action.meta.offset === 0 ? [] : state)
export const mergeIds = (state, action) => uniq(state.concat(action.payload.result))
export const makeRemoveId = (idResolver) => (state, action) =>
  state.filter((id) => id !== idResolver(action))

export const resetEntities = (state, action) => (action.meta.offset === 0 ? {} : state)
export const makeReplaceEntities = (entityType) => (state, action) =>
  action.payload.entities[entityType]
export const makeMergeEntities = (entityType) => (state, action) => ({
  ...state,
  ...action.payload.entities[entityType],
})
export const makeUpdateEntity = (idResolver, data, updater = updateEntity) => (state, action) => {
  if (typeof data === 'function') {
    return updater(state, idResolver(action), data(action))
  }
  return updater(state, idResolver(action), data)
}
export const makeRemoveEntity = (idResolver) => (state, action) => omit(state, idResolver(action))

export const setTrue = () => true
export const setFalse = () => false
