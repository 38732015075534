import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Label, List } from 'semantic-ui-react'

const ReportStats = ({ className, task }) => {
  if (!task.campaigns) {
    return null
  }

  const userKeywordsLength = (task.addKeywords || []).length + (task.subtractKeywords || []).length

  return (
    <List horizontal className={className}>
      <List.Item className="stats-item">
        <span>Количество РК:</span>
        <Label circular>{task.campaigns.length}</Label>
      </List.Item>
      <List.Item className="stats-item">
        <span>Всего минус-фраз:</span>
        <Label circular>{userKeywordsLength}</Label>
      </List.Item>
    </List>
  )
}

ReportStats.propTypes = {
  className: PropTypes.string.isRequired,
  task: PropTypes.shape({
    campaigns: PropTypes.arrayOf(PropTypes.any),
    addKeywords: PropTypes.arrayOf(PropTypes.string),
    subtractKeywords: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

const styledReportStats = styled(ReportStats)`
  margin-bottom: 10px !important;

  .stats-item {
    display: flex;
    align-items: baseline;

    .ui.label {
      margin-left: 0.4rem;
    }
  }
`

styledReportStats.WrappedComponent = ReportStats

export default styledReportStats
