import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { AuthRoute, withAuth } from 'auth-component'
import { NotificationEmitter } from 'pannier'
import GlobalStyles from '../styles'
import ErrorHandler from './components/ErrorHandler'
import Header from './components/Header'
import IndexPage from './IndexPage'
import TasksListPage from './TasksListPage'
import TaskPage from './TaskPage'
import NewTaskPage from './NewTaskPage'
import Page404 from './Page404'
import YandexNotAvailableMessage from './components/YandexNotAvailableMessage'
import * as routes from '../constants/routes'

const DefaultLayout = ({ component: Component, ...props }) => (
  <Fragment>
    <Header {...props} />
    <YandexNotAvailableMessage {...props} />
    <ErrorHandler>
      <Component {...props} />
    </ErrorHandler>
  </Fragment>
)

const RouteWithLayout = ({ layout: Layout, component, ...props }) => (
  <Route {...props} render={(props) => <Layout component={component} {...props} />} />
)

const IndexPageWithAuth = withAuth(IndexPage)
const TasksListPageWithAuth = withAuth(TasksListPage)
const TaskPageWithAuth = withAuth(TaskPage)
const NewTaskPageWithAuth = withAuth(NewTaskPage)

export default () => (
  <ErrorHandler>
    <GlobalStyles />
    <Switch>
      <AuthRoute path={routes.AUTH_PAGE} />
      <RouteWithLayout exact path="/" layout={DefaultLayout} component={IndexPageWithAuth} />
      <RouteWithLayout
        exact
        path={routes.TASKS_LIST}
        layout={DefaultLayout}
        component={TasksListPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.TASK}
        layout={DefaultLayout}
        component={TaskPageWithAuth}
      />
      <RouteWithLayout
        exact
        path={routes.NEW_TASK}
        layout={DefaultLayout}
        component={NewTaskPageWithAuth}
      />
      <Route component={Page404} />
    </Switch>
    <NotificationEmitter />
  </ErrorHandler>
)

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
}

RouteWithLayout.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
}
