import * as routes from '../constants/routes'
import * as pageNames from '../constants/page-names'

const getTitleElements = (route, { projectName, taskId }) => {
  switch (route) {
    case '/':
      return [pageNames.PROJECTS_LIST]
    case routes.TASKS_LIST:
      return [pageNames.TASKS_LIST, projectName]
    case routes.TASK:
      return [`Задача №${taskId}`, projectName]
    case routes.NEW_TASK:
      return [`Новая задача`, projectName]
    // no default
  }
  return null
}

const createPageTitle = (route, options) => {
  const titleElements = getTitleElements(route, options)
  const baseName = 'AirMedia.Минусатор'

  if (!titleElements) {
    return baseName
  }

  return [...titleElements.filter((item) => !!item), baseName].join(' | ')
}

export default createPageTitle
