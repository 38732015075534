import { getOperation, translate, translateOperation } from '../constants/adj-statuses'

const createAdjustReport = (data) => {
  if (!data || !data.length) {
    return null
  }

  return import('exceljs/dist/es5/exceljs.browser').then(({ Workbook }) => {
    const wb = new Workbook()
    const ws = wb.addWorksheet('Отчет')

    ws.columns = [
      { header: 'Задание', key: 'task', width: 25 },
      { header: '№ Кампании', key: 'campaignId', width: 15 },
      { header: 'Минус-фраза', key: 'negativeKeyword', width: 30 },
      { header: 'Статус', key: 'status', width: 30 },
      {
        header: 'Соответствие с единым списком минус-фраз / ключевой фразой',
        key: 'matches',
        width: 65,
      },
    ]

    const rows = data.map(({ campaignId, adjStatus, keyword, matches }) => [
      translateOperation(getOperation(adjStatus)),
      campaignId,
      keyword,
      translate(adjStatus),
      (matches || []).join(', '),
    ])

    ws.addRows(rows)

    return wb.xlsx.writeBuffer()
  })
}

export default createAdjustReport
