import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { RequestError } from 'pannier'
import TaskPage from './components/TaskPage'
import {
  FETCH_DETAILS_FAILURE,
  fetchTaskDetails,
  getAdjustReport,
  getTask,
  setReportLayout,
  syncTask,
} from '../../stores/tasks'
import { fetchYandexCampaigns } from '../../stores/yandexCampaigns'
import * as taskPageViews from '../../constants/task-page-views'
import { GROUPED } from '../../constants/report-layout'

const REPORT_ACCEPTABLE_SIZE = 30000

const mapStateToProps = (
  state,
  {
    match: {
      params: { taskId },
    },
  }
) => {
  const { view } = qs.parse(window.location.search)

  return {
    view,
    task: getTask(state, taskId) || {},
    adjustReportTotalCount: getAdjustReport(state, taskId).length,
  }
}

class TaskPageContainer extends Component {
  state = {
    taskId: null,
    needConfirmGroupedLayout: false,
  }

  static getDerivedStateFromProps(props, state) {
    const { task, adjustReportTotalCount } = props

    if (task && task.id !== state.taskId) {
      return {
        taskId: task.id,
        needConfirmGroupedLayout: adjustReportTotalCount > REPORT_ACCEPTABLE_SIZE,
      }
    }

    return null
  }

  componentDidMount() {
    const { projectId, taskId } = this.props.match.params

    if (!this.props.view) {
      this.setQuery({ view: taskPageViews.REPORT }, true)
    }

    this.props.fetchTaskDetails(projectId, taskId)
    this.props.fetchYandexCampaigns(projectId)
  }

  setView = (e, { view }) => {
    if (view === this.props.view) {
      return
    }

    this.setQuery({ view })
  }

  setQuery = (query, replace) => {
    const newQuery = { ...query }

    if (!newQuery.view) {
      newQuery.view = this.props.view
    }

    const newPath = `${window.location.pathname}?${qs.stringify(newQuery)}`

    if (replace) {
      this.props.history.replace(newPath)
    } else {
      this.props.history.push(newPath)
    }
  }

  syncTask = () => {
    const { projectId, taskId } = this.props.match.params

    this.props.syncTask(projectId, taskId)
  }

  setReportLayout = (layout) => {
    if (layout === GROUPED) {
      this.setState({
        needConfirmGroupedLayout: false,
      })
    }

    this.props.setReportLayout(layout)
  }

  render() {
    const {
      match: {
        params: { taskId },
      },
      view,
      task,
    } = this.props

    return (
      <RequestError types={[FETCH_DETAILS_FAILURE]}>
        <TaskPage
          taskId={taskId}
          task={task}
          setView={this.setView}
          syncTask={this.syncTask}
          setQuery={this.setQuery}
          setReportLayout={this.setReportLayout}
          view={view}
          needConfirmGroupedLayout={this.state.needConfirmGroupedLayout}
        />
      </RequestError>
    )
  }
}

TaskPageContainer.defaultProps = {
  view: null,
  task: null,
}

TaskPageContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
      taskId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fetchTaskDetails: PropTypes.func.isRequired,
  syncTask: PropTypes.func.isRequired,
  fetchYandexCampaigns: PropTypes.func.isRequired,
  setReportLayout: PropTypes.func.isRequired,
  view: PropTypes.string,
  task: PropTypes.objectOf(PropTypes.any),
}

export default connect(mapStateToProps, {
  fetchTaskDetails,
  syncTask,
  fetchYandexCampaigns,
  setReportLayout,
})(TaskPageContainer)
