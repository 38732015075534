import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { signOut } from 'auth-component'
import Header from './components/Header'
import { fetchProfile, logout, isFetching, getLogin } from '../../../stores/user'

const mapStateToProps = (state) => ({
  isProfileFetching: isFetching(state),
  login: getLogin(state),
})

class HeaderContainer extends Component {
  constructor(props) {
    super(props)

    this.signOut = signOut
  }

  componentDidMount() {
    this.props.fetchProfile()
  }

  handleSignOut = () => {
    this.signOut()
    this.props.logout()
  }

  render() {
    const { match, isProfileFetching, login } = this.props

    return (
      <Header
        match={match}
        isProfileFetching={isProfileFetching}
        login={login}
        handleSignOut={this.handleSignOut}
      />
    )
  }
}

HeaderContainer.defaultProps = {
  login: null,
}

HeaderContainer.propTypes = {
  fetchProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isProfileFetching: PropTypes.bool.isRequired,
  login: PropTypes.string,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default connect(mapStateToProps, { fetchProfile, logout })(HeaderContainer)
