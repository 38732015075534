import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import AdjustReport from './components/AdjustReport'
import {
  getAdjustReport,
  getAdjustReportTotals,
  getAggregatedAdjustReport,
  getReportLayout,
  getTask,
  isCorrectionSuccessful,
} from '../../../../stores/tasks'
import createAdjustReport from '../../../../utils/createAdjustReport'
import * as adjStatuses from '../../../../constants/adj-statuses'
import { LIST } from '../../../../constants/report-layout'

const ADJ_STATUSES = adjStatuses.getStatuses()

const applyFilter = (keywords, filter) =>
  (keywords && keywords.filter((kwd) => filter.includes(kwd.adjStatus))) || []

const mapStateToProps = (state, { taskId, needConfirmGroupedLayout }) => {
  const task = getTask(state, taskId) || {}
  const { status } = qs.parse(window.location.search)
  const layout = getReportLayout(state)

  return {
    task,
    status,
    layout,
    adjustReportItems:
      needConfirmGroupedLayout || layout === LIST
        ? getAdjustReport(state, taskId)
        : getAggregatedAdjustReport(state, taskId),
    adjustReportItemsForExport: () => getAdjustReport(state, taskId),
    adjustReportTotals: getAdjustReportTotals(state, taskId),
    isCorrectionSuccessful: isCorrectionSuccessful(state, taskId),
  }
}

class AdjustReportContainer extends Component {
  state = {
    isReportPreparing: false,
  }

  componentDidMount() {
    if (this.props.status) {
      return
    }

    let status = ADJ_STATUSES.filter(adjStatuses.isAnomaly)

    if (this.props.isCorrectionSuccessful) {
      status = ADJ_STATUSES.filter(
        (status) => status !== adjStatuses.UNCHANGED && !adjStatuses.isAnomaly(status)
      )
    }

    this.props.setQuery({ status: status.toString() }, true)
  }

  onDownloadClick = async () => {
    this.setState({ isReportPreparing: true })

    const { adjustReportItemsForExport, task } = this.props
    const report = await createAdjustReport(
      applyFilter(adjustReportItemsForExport(), this.getFilter())
    )

    const data = new Blob([report], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(data)

    const a = document.createElement('a')
    a.href = url
    a.download = `${task.id}_negative-keywords-report.xlsx`

    document.body.appendChild(a)
    a.click()
    this.setState({ isReportPreparing: false })
  }

  getFilter() {
    return this.props.status.split(',')
  }

  setFilter = (filter) => this.props.setQuery({ status: filter.toString() })

  render() {
    const {
      isCorrectionSuccessful,
      needConfirmGroupedLayout,
      task,
      status,
      adjustReportTotals,
      adjustReportItems,
      layout,
      setReportLayout,
    } = this.props

    if (!status) {
      return null
    }

    const filter = this.getFilter()

    return (
      <AdjustReport
        task={task}
        adjustReportItems={applyFilter(adjustReportItems, filter)}
        adjustReportTotals={adjustReportTotals}
        filter={filter}
        setFilter={this.setFilter}
        onDownloadClick={this.onDownloadClick}
        isCorrectionSuccessful={isCorrectionSuccessful}
        isReportPreparing={this.state.isReportPreparing}
        needConfirmGroupedLayout={needConfirmGroupedLayout}
        layout={needConfirmGroupedLayout ? LIST : layout}
        setLayout={setReportLayout}
      />
    )
  }
}

AdjustReportContainer.defaultProps = {
  status: null,
}

AdjustReportContainer.propTypes = {
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.string,
  adjustReportItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  adjustReportItemsForExport: PropTypes.func.isRequired,
  adjustReportTotals: PropTypes.objectOf(PropTypes.any).isRequired,
  setQuery: PropTypes.func.isRequired,
  isCorrectionSuccessful: PropTypes.bool.isRequired,
  needConfirmGroupedLayout: PropTypes.bool.isRequired,
  layout: PropTypes.string.isRequired,
  setReportLayout: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(AdjustReportContainer)
