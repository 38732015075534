const isSingleLine = (value) => /\n/.test(value) === false

const whitespaceRe = /^\s*$/

const withoutEmptyLines = (value) =>
  value
    .split('\n')
    .filter((v) => whitespaceRe.test(v) === false)
    .join('\n')

export default (value) => {
  let normValue = value !== '' ? withoutEmptyLines(value) : value

  if (normValue !== '' && isSingleLine(normValue)) {
    if (normValue.indexOf(' -') > 0) {
      normValue = normValue.replace(/ -/g, '\n')

      return normValue[0] === '-' ? normValue.substr(1) : normValue
    }

    if (normValue.indexOf(',') > 0) {
      return normValue
        .split(',')
        .map((v) => v.trim())
        .join('\n')
    }
  }

  return normValue
}
