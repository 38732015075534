import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { reduxForm, Field, Fields } from 'redux-form'
import styled from 'styled-components/macro'
import { Form, Grid, Button, Checkbox, Progress, Divider, Message, Dimmer } from 'semantic-ui-react'
import { CheckboxReduxForm, ErrorMessage, InputReduxForm } from 'pannier/form'
import Campaigns from './Campaigns'
import KeywordsFields from './KeywordsFields'
import { validate, getSubmitError } from '../../../utils/taskFormUtils'
import { COLOR_GREY, TEXT_BLACK } from '../../../styles/variables'

const NewTaskFormWrap = styled.div`
  .package-settings {
    margin-bottom: 15px;
  }

  .center-wrap {
    padding: 0 1rem;
  }

  .submit-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ui.progress {
      flex-basis: 100%;
      margin-bottom: 0;
    }

    .btn-continue {
      margin-left: 20px;
    }
  }

  .impressions-threshold {
    .field {
      max-width: 300px;

      .ui.input input {
        text-align: right;
      }
    }

    .description {
      font-size: 0.9em;
      line-height: 1.3em;
      margin-top: 1em;
      margin-bottom: 1em;
      color: ${COLOR_GREY};
    }
  }
`
const DimmerText = styled.p`
  color: ${TEXT_BLACK} !important;
`

const ReorderDescription = () => (
  <>
    <p>
      <strong>ВНИМАНИЕ!</strong> Включение этой опции может сильно замедлить выполнение задачи.
    </p>
    <p>
      <strong>ВНИМАНИЕ!</strong> Для минус-фраз с оператором <code>[]</code> недоступны некоторые
      функции оптимизации, так как для них не собирается статистика по показам. Например, у таких
      фраз всегда будет наивысший приоритет для добавления в список единых минус-фраз, или они
      никогда не будут исключены по порогу минимальной частотности.
    </p>
    <p>Удалить из единого списка минус-фразы, частотность комбинаций которых ниже, чем:</p>
    <ul>
      <li>указанное минимальное значение;</li>
      <li>
        частотность комбинаций добавляемых новых минус-фраз (когда в едином списке нет свободного
        места под новые).
      </li>
    </ul>
    <p>Оценка применяется как к новым минусам, указанным выше, так и к текущим, внесённым ранее.</p>
  </>
)

const CheckCrossingWithKeywordsDescription = () => (
  <>
    <p>
      Будут удалены те минус-фразы, которые пересекаются хотя бы с одной активной ключевой фразой
      в РК.
    </p>
    <p>
      Проверка производится как для существующих минус-фраз в едином списке, так и для добавляемых.
    </p>
  </>
)

const NewTaskForm = ({
  handleSubmit,
  projectId,
  batchMode,
  batchError,
  batchCurrent,
  batchTotal,
  changeMode,
  error,
  submitFailed,
  acceptChanges,
  isCreating,
}) => {
  const [optimizationEnabled, setOptimizationEnable] = useState(false)
  const handleChangeOptimization = useCallback(
    (e, checked) => {
      setOptimizationEnable(checked)
    },
    [setOptimizationEnable]
  )

  return (
    <NewTaskFormWrap>
      <Form onSubmit={handleSubmit}>
        {!batchMode && (
          <Message info content="Для одной задачи можно выбрать до 50 рекламных кампаний." />
        )}
        <Dimmer.Dimmable>
          <Dimmer active={!acceptChanges} inverted>
            <DimmerText>Пожалуйста, подождите, идет сохранение данных...</DimmerText>
          </Dimmer>
          <Grid celled="internally">
            <Grid.Row>
              <Grid.Column width={10}>
                <Field name="name" component={InputReduxForm} label="Название задачи" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Checkbox
                  className="package-settings"
                  checked={batchMode}
                  label="Создать несколько задач, по 50 кампаний"
                  onClick={changeMode}
                />
                <Field name="campaigns" component={Campaigns} projectId={projectId} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Fields names={['addKeywords', 'subtractKeywords']} component={KeywordsFields} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div className="center-wrap">
            <Field
              name="removeDuplicates"
              component={CheckboxReduxForm}
              label="Автоматически удалять дубли фраз из списков выше"
              description="Дубли фраз будут удалены только в рамках одного списка. По прежнему будет возвращена ошибка в случаях, когда фразы дублирующие друг-друга будут находиться в разных списках."
            />
          </div>
          <Divider />
          <div className="center-wrap impressions-threshold">
            <Field
              name="impressionsThreshold"
              component={InputReduxForm}
              label="Минимальная частотность"
            />
            <div className="description">
              Минимальная частотность минус-фраз в комбинации с тематическими ключами из РК.
              <br />
              Если заполнено, то в единый список минус-фраз не будут добавлены новые минус-фразы,
              для которых частотность комбинаций с тематическими ключами ниже, чем заданное
              значение. Оценка минус-фраз производится для каждой РК индивидуально.
            </div>
          </div>
          <div className="center-wrap">
            <Field
              name="optimization"
              component={CheckboxReduxForm}
              label="Оптимизация списка единых минус-фраз"
              description={<ReorderDescription />}
              onChange={handleChangeOptimization}
            />
          </div>
          <div className="center-wrap">
            <Field
              name="checkCrossingWithKeywords"
              component={CheckboxReduxForm}
              label="Проверка пересечения с ключевыми фразами"
              description={<CheckCrossingWithKeywordsDescription />}
              disabled={!optimizationEnabled}
            />
          </div>
        </Dimmer.Dimmable>
        <Divider />
        <div className="center-wrap">
          <ErrorMessage error={getSubmitError(error, submitFailed)} padded="bottom" />
          <div className="submit-controls">
            {batchTotal ? (
              <Progress
                value={batchCurrent}
                total={batchTotal}
                progress="ratio"
                active
                error={batchError}
              />
            ) : (
              <div>
                <Button positive content="Добавить" loading={isCreating} />
                <Button as={Link} to={`/${projectId}`} content="Отменить" />
              </div>
            )}
            {batchError && (
              <Button
                basic
                color="blue"
                content="Продолжить"
                loading={isCreating}
                className="btn-continue"
              />
            )}
          </div>
        </div>
      </Form>
    </NewTaskFormWrap>
  )
}

NewTaskForm.defaultProps = {
  error: null,
  batchCurrent: null,
  batchTotal: null,
}

NewTaskForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  batchMode: PropTypes.bool.isRequired,
  batchError: PropTypes.bool.isRequired,
  batchCurrent: PropTypes.number,
  batchTotal: PropTypes.number,
  changeMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitFailed: PropTypes.bool.isRequired,
  acceptChanges: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'newTask',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(NewTaskForm)
