// semantic-ui colors
export const COLOR_RED = '#db2828'
export const COLOR_ORANGE = '#f2711c'
export const COLOR_YELLOW = '#fbbd08'
export const COLOR_OLIVE = '#b5cc18'
export const COLOR_GREEN = '#21ba45'
export const COLOR_TEAL = '#00b5ad'
export const COLOR_BLUE = '#2185d0'
export const COLOR_VIOLET = '#6435c9'
export const COLOR_PURPLE = '#a333c8'
export const COLOR_PINK = '#e03997'
export const COLOR_BROWN = '#a5673f'
export const COLOR_GREY = '#838383'
export const COLOR_BLACK = '#1b1c1d'

export const TEXT_COLOR_DARK = 'rgba(0, 0, 0, 0.85)'
export const TEXT_COLOR_LIGHT = 'rgba(0, 0, 0, 0.4)'
export const TEXT_COLOR_MUTED = 'rgba(0, 0, 0, 0.6)'

export const DIVIDER = 'rgba(34,36,38,.15)'

export const ERROR_BORDER = '#e0b4b4'

export const SELECTED_ITEM_BG = 'rgba(0, 0, 0, .03)'
export const ERROR_BG = '#fff6f6'

// other colors
export const TEXT_BLACK = 'rgba(0, 0, 0, .87)'
export const TEXT_HIGHLIGHT = '#fc0'
export const TEXT_LINK = '#4183c4'
export const TEXT_DISABLED = 'rgba(40, 40, 40, 0.3)'
