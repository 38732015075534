const pointerToMessageKey = (str, prefix, postfix) => {
  if (str) {
    const pointer = str
      .replace(/^data(\.)?/, prefix ? `${prefix}.` : '')
      .replace(/(\w+)\[([0-9]+)\]/g, '$1')
      .replace(/\.\[/g, '[')
    return postfix ? `${pointer}.${postfix}` : pointer
  }

  return null
}

export default pointerToMessageKey
