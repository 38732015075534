import { makeTrans } from 'pannier'

const getKeywordsMaxWords = ({ max, value }) =>
  `Минус-фраза не может состоять более чем из ${max} слов: ${value}`
const getKeywordsMaxLength = ({ max, word, value }) =>
  `В минус-фразе "${value}" превышена допустимая длина слова "${word}" в ${max} символов.`
const getKeywordsIllegalChar = ({ word, value }) =>
  word
    ? `В минус-фразе "${value}" в слове "${word}" используются недопустимые символы.`
    : `В минус-фразе "${value}" используются недопустимые символы.`
const getKeywordsDuplicate = ({ value, duplicates }) => {
  if (duplicates) {
    return `Фразы дублируют друг-друга: ${duplicates.join(', ')}`
  }
  return `Фраза "${value}" является дублем другой фразы`
}

const get4xxError = ({ status }) =>
  `Не удалось обработать форму, сервер вернул ответ с кодом: ${status}.`
const get5xxError = ({ status }) =>
  `Сервер временно недоступен (${status}). Пожалуйста, попробуйте отправить данные позже. Если ошибка сохранится, обратитесь в службу поддержки.`

export default makeTrans({
  'form.submit.unk_error':
    'Извините, при обработке формы возникла неизвестная ошибка. Повторите попытку и обратитесь в службу поддержки, если ошибка сохранится.',
  'form.submit.error': 'Пожалуйста, исправьте ошибки и повторите отправку данных.',

  'request_errors.403': 'Недостаточно прав.',
  'request_errors.404': 'Ничего не найдено.',

  'notifications.delete_task': 'Вы не можете удалить задачу в процессе выполнения.',

  'search_filter.max_length_error': 'Превышена длина фильтра',
  'search_filter.sequence_error': 'Минус-слова должны быть перечислены в конце запроса',
  'search_filter.empty_op_val_error': 'Недопустимый запрос',

  'new_task_form.keywords_placeholder':
    'Введите ключевые фразы (каждую с новой строки)...\n\nПри вставке данных из буфера обмена поддерживается формат совместимый с Директ.Коммандером и веб-интерфейсом Яндекс.Директа',
  'new_task_form.campaigns.min_items': 'Должна быть выбрана хотя бы одна рекламная кампания.',
  'new_task_form.campaigns.max_items':
    'Выбрано слишком много рекламных кампаний. Пожалуйста, сократите количество кампаний' +
    ' или воспользуйтесь режимом пакетного добавления задач.',
  'new_task_form.keywords.required':
    'Должна быть указана хотя бы одна ключевая фраза в одном из полей.',
  'new_task_form.add_keywords.max_words': getKeywordsMaxWords,
  'new_task_form.subtract_keywords.max_words': getKeywordsMaxWords,
  'new_task_form.add_keywords.max_length': getKeywordsMaxLength,
  'new_task_form.subtract_keywords.max_length': getKeywordsMaxLength,
  'new_task_form.add_keywords.illegal_char': getKeywordsIllegalChar,
  'new_task_form.subtract_keywords.illegal_char': getKeywordsIllegalChar,
  'new_task_form.add_keywords.duplicate': getKeywordsDuplicate,
  'new_task_form.subtract_keywords.duplicate': getKeywordsDuplicate,

  'new_task_form.error.4xx': get4xxError,
  'new_task_form.error.5xx': get5xxError,
})
