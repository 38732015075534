import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Header, Tab, Menu, Label, Loader, Button } from 'semantic-ui-react'
import CampaignsList from './CampaignsList'
import TaskSettings from './TaskSettings'
import AdjustReport from './AdjustReport'
import Tooltip from '../../components/Tooltip'
import TaskStatus from '../../components/TaskStatus'
import * as taskStatuses from '../../../constants/task-statuses'
import * as taskPageViews from '../../../constants/task-page-views'
import * as adjStatuses from '../../../constants/campaign-adj-statuses'
import * as syncStatuses from '../../../constants/sync-statuses'

const TaskPageWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  padding-top: 30px;
  max-width: 65%;

  .menu-item-campaigns {
    padding-right: 50px !important;
  }

  .tab-label {
    position: absolute;
    bottom: 9px;
    right: 13px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    min-height: 43px;

    & .header {
      padding-top: 8px;
    }

    .multiline .timestamp {
      text-align: right;
    }
  }
`

const getActiveTabIndex = (view) =>
  [taskPageViews.REPORT, taskPageViews.CAMPAIGNS, taskPageViews.SETTINGS].indexOf(view)

const createMenuItem = (content, view, onClick) => (
  <Menu.Item key={view} content={content} view={view} onClick={onClick} />
)

const hasErrors = (campaigns) =>
  (campaigns || []).some(
    (item) =>
      item.adjStatus === adjStatuses.FAILED ||
      item.syncStatus === syncStatuses.ERROR ||
      item.syncStatus === syncStatuses.CONFLICT
  )

/* eslint-disable react/prop-types */
const getPanes = (
  setView,
  campaigns,
  taskId,
  setQuery,
  setReportLayout,
  needConfirmGroupedLayout
) => [
  {
    menuItem: createMenuItem('Отчет', taskPageViews.REPORT, setView),
    render: () => (
      <AdjustReport
        taskId={taskId}
        setQuery={setQuery}
        setReportLayout={setReportLayout}
        needConfirmGroupedLayout={needConfirmGroupedLayout}
        key={Math.random()}
      />
    ),
  },
  {
    menuItem: (
      <Menu.Item
        className="menu-item-campaigns"
        key={taskPageViews.CAMPAIGNS}
        view={taskPageViews.CAMPAIGNS}
        onClick={setView}
      >
        Кампании
        <Label className="tab-label" color={hasErrors(campaigns) ? 'red' : null}>
          {campaigns.length}
        </Label>
      </Menu.Item>
    ),
    render: ({ task: { status, projectId } }) => (
      <CampaignsList campaigns={campaigns} taskStatus={status} projectId={projectId} />
    ),
  },
  {
    menuItem: createMenuItem('Настройки', taskPageViews.SETTINGS, setView),
    render: ({ task }) => <TaskSettings task={task} />,
  },
]
/* eslint-enable react/prop-types */

const TaskPage = ({
  taskId,
  task,
  view,
  setView,
  syncTask,
  setQuery,
  setReportLayout,
  needConfirmGroupedLayout,
}) => {
  const campaigns = task && task.campaigns
  const isFetching = task && task.isFetching
  const isSyncing = task && task.isSyncing
  const isReadyToSync = task && task.status === taskStatuses.ADJUSTED

  return (
    <TaskPageWrap>
      {!task || !campaigns || isFetching ? (
        <Loader active size="large" content="Загрузка данных по задаче..." />
      ) : (
        <Fragment>
          <div className="heading">
            <Header as="h3" content={task && task.name} textAlign="center" />
            {isReadyToSync ? (
              <Fragment>
                <Button
                  basic
                  positive
                  content="Синхронизация"
                  loading={isSyncing}
                  onClick={syncTask}
                  data-for="sync-btn"
                  data-tip="Выгрузить изменения в Я.Директ"
                />
                <Tooltip id="sync-btn" place="left" />
              </Fragment>
            ) : (
              task && (
                <TaskStatus
                  status={task.status}
                  canRestart={task.restart === 'enabled'}
                  updatedAt={task.updatedAt}
                  mode="multiline"
                />
              )
            )}
          </div>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={getPanes(
              setView,
              campaigns,
              taskId,
              setQuery,
              setReportLayout,
              needConfirmGroupedLayout
            )}
            task={task}
            activeIndex={getActiveTabIndex(view)}
          />
        </Fragment>
      )}
    </TaskPageWrap>
  )
}

TaskPage.defaultProps = {
  view: null,
  task: null,
}

TaskPage.propTypes = {
  taskId: PropTypes.string.isRequired,
  view: PropTypes.string,
  task: PropTypes.shape({
    name: PropTypes.string,
    campaigns: PropTypes.arrayOf(PropTypes.any),
    restart: PropTypes.string,
    isFetching: PropTypes.bool,
    isSyncing: PropTypes.bool,
    status: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  needConfirmGroupedLayout: PropTypes.bool.isRequired,
  syncTask: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  setReportLayout: PropTypes.func.isRequired,
}

export default TaskPage
