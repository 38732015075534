import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Message, Button } from 'semantic-ui-react'
import { StatusFilter } from 'pannier'
import ReportStats from './ReportStats'
import AdjustReportFilter from './AdjustReportFilter'
import GroupedDataTable from './GroupedDataTable'
import ListDataTable from './ListDataTable'
import LayoutIcon from './LayoutIcon'
import * as adjStatuses from '../../../../../constants/adj-statuses'
import { GROUPED, LIST } from '../../../../../constants/report-layout'

const componentLayoutMap = {
  [GROUPED]: GroupedDataTable,
  [LIST]: ListDataTable,
}

const ReportControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  .buttons {
    text-align: right;
  }
`

const AdjustReport = ({
  task,
  adjustReportItems,
  adjustReportTotals,
  needConfirmGroupedLayout,
  filter,
  setFilter,
  onDownloadClick,
  isCorrectionSuccessful,
  isReportPreparing,
  layout,
  setLayout,
}) => {
  const handleChangeLayout = (e, { layout }) => setLayout(layout)
  const LayoutComponent = componentLayoutMap[layout] || componentLayoutMap[LIST]

  return (
    <React.Fragment>
      {isCorrectionSuccessful ? <Message positive content="Корректировка прошла успешно." /> : null}
      <ReportControlsContainer>
        <ReportStats task={task} />
        {task.adGroups && task.adGroups.enabled && <AdjustReportFilter />}
        <div className="buttons">
          <Button
            icon="download"
            content="Скачать в .xlsx"
            loading={isReportPreparing}
            onClick={onDownloadClick}
          />
          <Button.Group>
            <Button
              icon={<LayoutIcon name="block layout" withWarning={needConfirmGroupedLayout} />}
              layout={GROUPED}
              disabled={layout === GROUPED}
              onClick={handleChangeLayout}
            />
            <Button
              icon={<LayoutIcon name="th list" />}
              layout={LIST}
              disabled={layout === LIST}
              onClick={handleChangeLayout}
            />
          </Button.Group>
        </div>
      </ReportControlsContainer>
      <StatusFilter
        filter={filter}
        totals={adjustReportTotals}
        setFilter={setFilter}
        translateStatus={adjStatuses.translate}
      />
      {adjustReportItems.length ? (
        <LayoutComponent items={adjustReportItems} />
      ) : (
        <Message
          info
          icon="frown outline"
          content="К сожалению, нет данных. Попробуйте изменить условия поиска."
        />
      )}
    </React.Fragment>
  )
}

AdjustReport.propTypes = {
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  adjustReportItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  adjustReportTotals: PropTypes.objectOf(PropTypes.any).isRequired,
  needConfirmGroupedLayout: PropTypes.bool.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilter: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  isCorrectionSuccessful: PropTypes.bool.isRequired,
  isReportPreparing: PropTypes.bool.isRequired,
  layout: PropTypes.oneOf([GROUPED, LIST]).isRequired,
  setLayout: PropTypes.func.isRequired,
}

export default AdjustReport
