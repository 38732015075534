import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

const normalizeKeys = (entity) => camelizeKeys(entity)

export const YANDEX_CAMPAIGN = new schema.Entity(
  'yandexCampaigns',
  {},
  {
    processStrategy: normalizeKeys,
  }
)

export const YANDEX_CAMPAIGNS_ARRAY = [YANDEX_CAMPAIGN]
