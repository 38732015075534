export const PENDING = 'pending'
export const SYNCED = 'synced'
export const ERROR = 'error'
export const CONFLICT = 'conflict'

export const getDescription = (status) => {
  switch (status) {
    case PENDING:
      return 'Изменения не выгружались в Яндекс.Директ.'
    case SYNCED:
      return 'Изменения успешно выгружены.'
    case ERROR:
      return 'В процессе синхронизации возникла ошибка.'
    case CONFLICT:
      return 'Невозможно выгрузить изменения из-за конфликтов в данных.'
    // no default
  }

  return status
}

export const translate = (status) => {
  switch (status) {
    case PENDING:
      return 'не обновлено'
    case SYNCED:
      return 'обновлено'
    case ERROR:
      return 'ошибка'
    case CONFLICT:
      return 'конфликт'
    // no default
  }

  return status
}
