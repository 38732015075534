import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import { makeReplaceEntities } from '../actionHandlers'
import { makeBooleanFlagReducer } from '../makeReducer'

export * from './actions'
export * from './selectors'
export * from './constants'

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: makeReplaceEntities('yandexCampaigns'),
    },
    {}
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.payload.result,
    },
    []
  ),
  isFetching: makeBooleanFlagReducer(
    types.FETCH_LIST_REQUEST,
    types.FETCH_LIST_SUCCESS,
    types.FETCH_LIST_FAILURE
  ),
})
