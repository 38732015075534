import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import { authErrorMiddleware, authMiddleware } from 'auth-component/lib/redux'
import rollbar, { rollbarMiddleware } from '../rollbar'
import rootReducer from './rootReducer'
import logMiddleware from './logMiddleware'

const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      thunk,
      authErrorMiddleware,
      authMiddleware,
      apiMiddleware,
      logMiddleware(rollbar),
      rollbarMiddleware
    )
  )

export default configureStore
