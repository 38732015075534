import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Button, Confirm, Dimmer, Icon, List, Loader } from 'semantic-ui-react'
import Tooltip from '../../../../components/Tooltip'
import TaskStatus from '../../../../components/TaskStatus'
import * as taskStatuses from '../../../../../constants/task-statuses'
import { TEXT_COLOR_MUTED } from '../../../../../styles/variables'
import Description from './Description'

const canDelete = (status) =>
  ![taskStatuses.SYNCING, taskStatuses.SYNC_FAILED, taskStatuses.COMPLETED].includes(status)

const TasksListItem = ({ className, task, isTaskDeleting, deleteTask }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const handleDelete = useCallback(() => {
    setConfirmOpen(true)
  }, [])
  const handleConfirm = useCallback(() => {
    deleteTask()
    setConfirmOpen(false)
  }, [deleteTask])
  const handleCancel = useCallback(() => {
    setConfirmOpen(false)
  }, [])

  return (
    <Dimmer.Dimmable as={List.Item} key={task.id} className={className}>
      <List.Content floated="right">
        {canDelete(task.status) && (
          <>
            <Button
              basic
              negative
              icon
              size="small"
              onClick={handleDelete}
              data-for={`tasks-list-tooltip-${task.id}`}
              data-tip="Удалить задачу"
            >
              <Icon name="remove" color="red" />
              <Tooltip id={`tasks-list-tooltip-${task.id}`} />
            </Button>
            <Confirm
              open={confirmOpen}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              cancelButton="Отмена"
              header={`Вы точно хотите удалить задачу №${task.id}?`}
              content={<p className="content">Данные будут утеряны безвозвратно.</p>}
            />
          </>
        )}
      </List.Content>
      <List.Content key={task.id} as={Link} to={`/${task.projectId}/${task.id}`}>
        <Dimmer active={isTaskDeleting} inverted>
          <Loader />
        </Dimmer>
        <List.Header>
          <span className="id">{task.id}</span>: {task.name}
        </List.Header>
        <List.Description>
          <TaskStatus
            status={task.status}
            canRestart={task.restart === 'enabled'}
            updatedAt={task.updatedAt}
            mode="multiline"
          />
        </List.Description>
        <List.Description>
          <Description task={task} />
        </List.Description>
      </List.Content>
    </Dimmer.Dimmable>
  )
}

TasksListItem.propTypes = {
  className: PropTypes.string.isRequired,
  task: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    restart: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  isTaskDeleting: PropTypes.bool.isRequired,
  deleteTask: PropTypes.func.isRequired,
}

const StyledTasksListItem = styled(TasksListItem)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  .header > .id {
    color: ${TEXT_COLOR_MUTED};
  }

  .description {
    margin-top: 0.5em;
  }

  .floated.content:first-child {
    order: 2;
    opacity: 0;
  }

  .content:last-child {
    width: 100%;
  }

  &:hover .content:first-child {
    opacity: 1;
  }

  &.item .content .description:last-child {
    padding-left: 1.4rem;
  }
`

StyledTasksListItem.WrappedComponent = TasksListItem

export default StyledTasksListItem
