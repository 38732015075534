import React, { Component } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import styled from 'styled-components/macro'
import { Icon } from 'semantic-ui-react'
import Tooltip from './Tooltip'

const CopyButtonWrap = styled.div`
  .btn-copy {
    cursor: pointer;
    opacity: 0.3;

    &:hover {
      opacity: 0.45;
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const makeHandleCopy = (text) => (e) => {
  e.clipboardData.setData('text/plain', text)
  e.preventDefault()
}

const TEXT_BEFORE_COPY = 'Скопировать в буфер обмена'
const TEXT_AFTER_COPY = 'Скопировано'

class CopyButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipText: props.tooltipText || TEXT_BEFORE_COPY,
    }
  }

  onClick = () => {
    this.setState({ tooltipText: TEXT_AFTER_COPY })
    document.execCommand('copy')
  }

  onMouseLeave = () => this.setState({ tooltipText: this.props.tooltipText || TEXT_BEFORE_COPY })

  render() {
    const { text, iconName } = this.props
    const tooltipId = uniqueId('url-with-controls-')

    return (
      <CopyButtonWrap>
        <Icon
          name={iconName || 'clone'}
          className="btn-copy"
          onCopy={makeHandleCopy(text)}
          onClick={this.onClick}
          onMouseLeave={this.onMouseLeave}
          data-for={tooltipId}
          data-tip="tip-placeholder"
        />
        <Tooltip id={tooltipId} delayShow={0} getContent={() => this.state.tooltipText} />
      </CopyButtonWrap>
    )
  }
}

CopyButton.defaultProps = {
  text: null,
  tooltipText: null,
  iconName: null,
}

CopyButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipText: PropTypes.string,
  iconName: PropTypes.string,
}

export default CopyButton
