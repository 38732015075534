import { createSelector } from 'reselect'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import transform from 'lodash/transform'
import uniq from 'lodash/uniq'
import { getYandexCampaign } from '../yandexCampaigns'
import groupReportItems from '../../utils/groupReportItems'
import isTaskReady from '../../utils/isTaskReady'
import * as adjStatuses from '../../constants/adj-statuses'
import * as adjLevelFilters from '../../constants/adj-level-filters'

const getTasksById = (state) => state.tasks.byId
const getTasksIds = (state) => state.tasks.ids

export const isFetching = (state) => state.tasks.isFetching
export const isCreating = (state) => state.tasks.isCreating
export const getOffset = (state) => state.tasks.offset
export const getTotal = (state) => state.tasks.total
export const getReportFilter = (state) => state.tasks.reportFilter
export const getReportLayout = (state) => state.tasks.reportLayout

export const getTasks = createSelector(getTasksById, getTasksIds, (items, ids) =>
  ids.map((id) => items[id])
)

export const getTask = createSelector(
  getTasksById,
  (state, id) => ({ state, id }),
  (items, { state, id }) => ({
    ...items[id],
    campaigns:
      items[id] &&
      items[id].campaigns &&
      items[id].campaigns.map((campaign) => ({
        ...getYandexCampaign(state, campaign.id),
        ...campaign,
      })),
  })
)

const getTaskProperty = (state, taskId, property) => {
  const task = getTask(state, taskId)

  if (task) {
    return task[property]
  }

  return null
}

export const isTaskFetching = (state, taskId) => getTaskProperty(state, taskId, 'isFetching')

export const isTaskDeleting = (state, taskId) => getTaskProperty(state, taskId, 'isDeleting')

export const isTaskSyncing = (state, taskId) => getTaskProperty(state, taskId, 'isSyncing')

export const getNegativeKeywords = createSelector(getTask, (task) => {
  const campaigns = task && task.campaigns

  if (!campaigns) {
    return null
  }

  return campaigns.reduce((acc, campaign) => {
    const { id, negativeKeywords } = campaign

    if (Array.isArray(negativeKeywords)) {
      acc.push(...negativeKeywords.map((nkwd) => ({ ...nkwd, campaignId: id })))
    }

    return acc
  }, [])
})

export const isCorrectionSuccessful = createSelector(
  getTask,
  getNegativeKeywords,
  (task, negativeKeywords) =>
    !negativeKeywords.some((item) => adjStatuses.isAnomaly(item.adjStatus)) &&
    isTaskReady(task.status)
)

const resolveMatches = (relevantKeywords, keyword, status) => {
  const matches =
    status === adjStatuses.CONFLICT
      ? uniq(flatten(relevantKeywords.map((x) => x.matches)))
      : relevantKeywords.map((x) => x.keyword)

  if (
    status === adjStatuses.ADDED ||
    status === adjStatuses.OMITTED ||
    status === adjStatuses.USELESS ||
    status === adjStatuses.CONFLICT
  ) {
    return matches.filter((x) => x !== keyword)
  }

  return matches
}

const resolveStatus = (adjStatus) => {
  switch (adjStatus) {
    case adjStatuses.ABSORBED:
    case adjStatuses.INFREQUENT:
    case adjStatuses.UNCHANGED:
      return adjStatuses.EXISTS
    default:
      return adjStatus
  }
}

const buildItemsForKeyword = (keyword, campaign, filter) => {
  let relevantKeywords = campaign.negativeKeywords.filter(
    (kwd) => kwd.matches && kwd.matches.includes(keyword)
  )

  if (filter !== adjLevelFilters.ALL) {
    relevantKeywords = relevantKeywords.filter(({ level }) => level === filter)
  }

  const byStatus = groupBy(relevantKeywords, 'adjStatus')
  return Object.entries(byStatus).map(([status, items]) => ({
    keyword,
    campaignId: campaign.id,
    adjStatus: resolveStatus(status),
    matches: resolveMatches(items, keyword, status),
  }))
}

export const getAdjustReport = createSelector(getTask, getReportFilter, (task, levelFilter) => {
  let items = []
  const campaigns = ((task && task.campaigns) || []).filter((campaign) =>
    Array.isArray(campaign.negativeKeywords)
  )

  if (task.addKeywords && task.addKeywords.length) {
    items = task.addKeywords.reduce((acc, keyword) => {
      campaigns.forEach((campaign) => {
        acc.push(...buildItemsForKeyword(keyword, campaign, levelFilter))
      })
      return acc
    }, items)
  }

  if (task.subtractKeywords && task.subtractKeywords.length) {
    items = task.subtractKeywords.reduce((acc, keyword) => {
      campaigns.forEach((campaign) => {
        const items = buildItemsForKeyword(keyword, campaign, levelFilter)

        if (items.length) {
          acc.push(...items)
        } else if (levelFilter !== adjLevelFilters.AD_GROUP) {
          acc.push({
            keyword,
            campaignId: campaign.id,
            adjStatus: adjStatuses.NOT_FOUND,
            matches: [],
          })
        }
      })
      return acc
    }, items)
  }

  const optimizationStatuses = [adjStatuses.ABSORBED, adjStatuses.DISPLACED, adjStatuses.INFREQUENT]
  const filterMatchesForAbsorbed = task.addKeywords
    ? (matches) => matches.filter((m) => !task.addKeywords.includes(m))
    : (matches) => matches

  campaigns.forEach((campaign) => {
    campaign.negativeKeywords.forEach((item) => {
      if (
        !optimizationStatuses.includes(item.adjStatus) ||
        (levelFilter !== adjLevelFilters.ALL && item.level !== levelFilter)
      ) {
        return
      }

      items.push({
        keyword: item.keyword,
        campaignId: campaign.id,
        adjStatus: item.adjStatus,
        matches:
          item.adjStatus === adjStatuses.ABSORBED ? filterMatchesForAbsorbed(item.matches) : [],
      })
    })
  })

  return items
})

export const getAggregatedAdjustReport = createSelector(getAdjustReport, groupReportItems)

export const getAdjustReportTotals = createSelector(getAdjustReport, (items) => {
  const grouped = groupBy(items, 'adjStatus')

  return transform(
    grouped,
    (acc, items, status) => {
      acc[status] = items.length
    },
    {}
  )
})
