import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Header, Button, Message, Loader } from 'semantic-ui-react'
import TasksList from './TasksList'

const TasksListPageWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
  padding-top: 50px;
  max-width: 40%;
`

const TasksListPage = ({ projectId, tasks, isFetching }) => (
  <TasksListPageWrap>
    <Fragment>
      <Header as="h3" content="Список задач" textAlign="center" />
      <Button
        as={Link}
        to={`/${projectId}/new-task`}
        basic
        positive
        content="Добавить задачу"
        icon="add"
        size="small"
      />
      <TasksList projectId={projectId} tasks={tasks} />
      {!tasks.length && !isFetching && <Message content="Задач нет." info />}
      {isFetching && <Loader inline="centered" size="large" content="Загрузка задач..." active />}
    </Fragment>
  </TasksListPageWrap>
)

TasksListPage.propTypes = {
  projectId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default TasksListPage
