export const PREFIX = '@tasks/'

export const RESET_STATE = `${PREFIX}RESET_STATE`

export const FETCH_LIST_REQUEST = `${PREFIX}FETCH_LIST_REQUEST`
export const FETCH_LIST_SUCCESS = `${PREFIX}FETCH_LIST_SUCCESS`
export const FETCH_LIST_FAILURE = `${PREFIX}FETCH_LIST_FAILURE`

export const FETCH_DETAILS_REQUEST = `${PREFIX}FETCH_DETAILS_REQUEST`
export const FETCH_DETAILS_SUCCESS = `${PREFIX}FETCH_DETAILS_SUCCESS`
export const FETCH_DETAILS_FAILURE = `${PREFIX}FETCH_DETAILS_FAILURE`

export const CREATE_REQUEST = `${PREFIX}CREATE_REQUEST`
export const CREATE_SUCCESS = `${PREFIX}CREATE_SUCCESS`
export const CREATE_FAILURE = `${PREFIX}CREATE_FAILURE`

export const DELETE_REQUEST = `${PREFIX}DELETE_REQUEST`
export const DELETE_SUCCESS = `${PREFIX}DELETE_SUCCESS`
export const DELETE_FAILURE = `${PREFIX}DELETE_FAILURE`

export const SYNC_REQUEST = `${PREFIX}SYNC_REQUEST`
export const SYNC_SUCCESS = `${PREFIX}SYNC_SUCCESS`
export const SYNC_FAILURE = `${PREFIX}SYNC_FAILURE`

export const SET_REPORT_FILTER = `${PREFIX}SET_REPORT_FILTER`
export const SET_REPORT_LAYOUT = `${PREFIX}SET_REPORT_LAYOUT`
