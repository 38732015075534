import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'
import * as adjLevelFilters from '../../../../../../../constants/adj-level-filters'

const getOptions = () => [
  { key: 0, text: adjLevelFilters.translate(adjLevelFilters.ALL), value: adjLevelFilters.ALL },
  {
    key: 1,
    text: adjLevelFilters.translate(adjLevelFilters.CAMPAIGN),
    value: adjLevelFilters.CAMPAIGN,
  },
  {
    key: 2,
    text: adjLevelFilters.translate(adjLevelFilters.AD_GROUP),
    value: adjLevelFilters.AD_GROUP,
  },
]

const DropdownStyles = { marginBottom: '5px', minWidth: '360px' }

const AdjustReportFilter = ({ filter, setReportFilter }) => (
  <Dropdown
    selection
    options={getOptions()}
    onChange={(e, { value }) => setReportFilter(value)}
    value={filter}
    style={DropdownStyles}
  />
)

AdjustReportFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  setReportFilter: PropTypes.func.isRequired,
}

export default AdjustReportFilter
