import { CREATE_FAILURE } from './tasks'

const LOG_ERROR_NAMES = ['InternalError']
const IGNORE_ACTIONS = [CREATE_FAILURE]

export default (rollbar) => () => (next) => (action) => {
  if (
    action &&
    action.error &&
    !IGNORE_ACTIONS.includes(action.type) &&
    action.payload &&
    LOG_ERROR_NAMES.includes(action.payload.name)
  ) {
    if (rollbar) {
      rollbar.error(action.payload.message, action.payload)
    } else {
      throw action.payload.message
    }
  }

  return next(action)
}
