import React from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import omit from 'lodash/omit'
import { Grid } from 'semantic-ui-react'
import { ErrorMessage, TextAreaReduxForm } from 'pannier/form'
import getMessage from '../../../messages'
import normalizeInputKeywords from '../../../utils/normalizeInputKeywords'

const errorRequiredMessage = getMessage('new_task_form.keywords.required')

const duplicateMsg = 'Фразы дублируют друг-друга'
const testForDuplicateMsg = (msg) => new RegExp(duplicateMsg).test(msg)
const prepareErrors = (errors) => errors.filter((msg) => !testForDuplicateMsg(msg))
const findDuplicateErrors = (errors) => uniq(errors.filter(testForDuplicateMsg))

const withoutError = (fieldProps) => ({
  ...fieldProps,
  meta: omit(fieldProps.meta, 'error'),
})

const makeOnChangeHandler = ({ input }) => (event) => {
  const { nativeEvent } = event

  if (
    nativeEvent.type === 'input' &&
    nativeEvent.inputType === 'insertFromPaste' &&
    input.value === ''
  ) {
    input.onChange(normalizeInputKeywords(nativeEvent.target.value))

    return
  }

  input.onChange(event)
}

const KeywordsFields = ({ addKeywords, subtractKeywords, ...props }) => {
  const addKeywordsErrors = addKeywords && addKeywords.meta.error
  const subtractKeywordsErrors = subtractKeywords && subtractKeywords.meta.error
  const duplicationErrors = findDuplicateErrors([
    ...(addKeywordsErrors || []),
    ...(subtractKeywordsErrors || []),
  ])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <TextAreaReduxForm
            label="Фразы для добавления в единые минус-фразы"
            rows={props.rows || 10}
            placeholder={getMessage('new_task_form.keywords_placeholder')}
            {...withoutError(addKeywords)}
            {...props}
            onChange={makeOnChangeHandler(addKeywords)}
          />
          {addKeywords.meta.touched &&
            Array.isArray(addKeywordsErrors) &&
            !addKeywordsErrors.includes(errorRequiredMessage) && (
              <ErrorMessage error={prepareErrors(addKeywordsErrors)} attached="bottom" />
            )}
          {(addKeywords.meta.touched || subtractKeywords.meta.touched) &&
            addKeywords.meta.pristine &&
            subtractKeywords.meta.pristine &&
            Array.isArray(addKeywordsErrors) &&
            addKeywordsErrors.includes(errorRequiredMessage) && (
              <ErrorMessage error={[errorRequiredMessage]} attached="bottom" />
            )}
          {duplicationErrors.length ? (
            <ErrorMessage error={duplicationErrors} attached="bottom" />
          ) : null}
        </Grid.Column>
        <Grid.Column width={8}>
          <TextAreaReduxForm
            label="Фразы для удаления из единых минус-фраз"
            rows={props.rows || 10}
            placeholder={getMessage('new_task_form.keywords_placeholder')}
            {...withoutError(subtractKeywords)}
            {...props}
            onChange={makeOnChangeHandler(subtractKeywords)}
          />
          {subtractKeywords.meta.touched && subtractKeywordsErrors && (
            <ErrorMessage error={prepareErrors(subtractKeywordsErrors)} attached="bottom" />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

KeywordsFields.defaultProps = {
  rows: null,
}

const fieldPropTypes = PropTypes.shape({
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    error: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    ),
  }).isRequired,
})

KeywordsFields.propTypes = {
  addKeywords: fieldPropTypes.isRequired,
  subtractKeywords: fieldPropTypes.isRequired,
  rows: PropTypes.number,
}

export default KeywordsFields
