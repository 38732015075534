import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TasksListItem from './components/TasksListItem'
import { deleteTaskWithNotification as deleteTask, isTaskDeleting } from '../../../../stores/tasks'

const mapStateToProps = (state, { task }) => ({
  isTaskDeleting: isTaskDeleting(state, task.id),
})

class TasksListItemContainer extends Component {
  deleteTask = () => {
    const { task } = this.props

    this.props.deleteTask(task.projectId, task.id)
  }

  render() {
    const { task, isTaskDeleting } = this.props

    return (
      <TasksListItem task={task} isTaskDeleting={isTaskDeleting} deleteTask={this.deleteTask} />
    )
  }
}

TasksListItemContainer.defaultProps = {
  isTaskDeleting: false,
}

TasksListItemContainer.propTypes = {
  task: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    restart: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  isTaskDeleting: PropTypes.bool,
  deleteTask: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { deleteTask })(TasksListItemContainer)
