import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Grid, Icon, Image, Segment } from 'semantic-ui-react'
import { isAuth } from 'auth-component'
import Breadcrumbs from '../../Breadcrumbs'
import * as routes from '../../../../constants/routes'
import svgLogo from '../../../../assets/logo.svg'

const renderLoginBtn = (isFetching, login, handleSignOut) => {
  if (isAuth()) {
    return (
      <Button
        as={Link}
        to={routes.AUTH_PAGE}
        onClick={handleSignOut}
        size="tiny"
        loading={isFetching}
        icon
        labelPosition="right"
      >
        {login || 'Выйти'}
        <Icon name="sign out" />
      </Button>
    )
  }

  return (
    <Button as={Link} to={routes.AUTH_PAGE} size="tiny" icon labelPosition="right">
      Войти
      <Icon name="sign in" />
    </Button>
  )
}

const Header = ({ isProfileFetching, login, handleSignOut, match }) => (
  <Segment attached inverted>
    <Grid container verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={1}>
          <Image as={Link} to="/" src={svgLogo} alt="nkwd-adjuster" size="mini" />
        </Grid.Column>
        <Grid.Column width={11}>
          <Breadcrumbs match={match} />
        </Grid.Column>
        <Grid.Column width={4} floated="right" textAlign="right">
          {renderLoginBtn(isProfileFetching, login, handleSignOut)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

Header.defaultProps = {
  login: null,
}

Header.propTypes = {
  isProfileFetching: PropTypes.bool.isRequired,
  login: PropTypes.string,
  handleSignOut: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default Header
