import * as taskStatuses from '../constants/task-statuses'

const NOT_READY_TASK_STATUSES = [
  taskStatuses.PENDING,
  taskStatuses.ADJUSTING,
  taskStatuses.ADJUST_FAILED,
]

const isTaskReady = (status) => !NOT_READY_TASK_STATUSES.includes(status)

export default isTaskReady
