import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

export const TASK = new schema.Entity(
  'tasks',
  {},
  {
    processStrategy: (entity) => {
      const normEntity = camelizeKeys(entity)

      // For backward compatible with prev spec of API
      if (!normEntity.optimization && 'reorder' in normEntity) {
        normEntity.optimization = {
          enabled: !!normEntity.reorder,
          checkCrossingWithKeywords: !!normEntity.reorder,
        }
      }

      return normEntity
    },
  }
)

export const TASK_ARRAY = [TASK]
