import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button, Icon, Loader } from 'semantic-ui-react'
import { ContextRequestError, transformColumnDataToQuery } from 'pannier'
import { ErrorMessage, ListSwap } from 'pannier/form'
import CampaignsFetchFailure from '../../../../components/CampaignsFetchFailure'
import { FETCH_LIST_FAILURE as FETCH_YANDEX_CAMPAIGNS_LIST_FAILURE } from '../../../../../stores/yandexCampaigns'

const CampaignsWrap = styled.div`
  position: relative;

  .ui.loader {
    top: 45%;
  }

  .icon-campaigns-failure {
    position: absolute;
    top: 0;
    left: 10px;
  }

  .campaigns-controls {
    margin-bottom: 0.5em;
  }
`

const ListItemContent = ({ id, name, archived, renderText }) => (
  <Fragment>
    {renderText(`[${id}] ${name}`)}
    {archived && (
      <Fragment>
        {' '}
        <Icon name="archive" />
      </Fragment>
    )}
  </Fragment>
)

ListItemContent.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  archived: PropTypes.bool.isRequired,
  renderText: PropTypes.func.isRequired,
}

const Campaigns = ({
  isFetching,
  campaigns,
  selectedCampaigns,
  onChange,
  refreshCampaigns,
  error,
  projectId,
}) => (
  <CampaignsWrap>
    <Loader active={isFetching} />
    <ContextRequestError types={[FETCH_YANDEX_CAMPAIGNS_LIST_FAILURE]}>
      <CampaignsFetchFailure projectId={projectId} className="icon-campaigns-failure" />
    </ContextRequestError>
    <ListSwap
      allItemsListHeader="Все кампании"
      selectedItemsListHeader="Выбранные кампании"
      allItems={campaigns}
      selectedItems={selectedCampaigns}
      onChange={onChange}
      searchProps={['id', 'name']}
      transformQuery={transformColumnDataToQuery}
      listItem={{
        itemContent: ListItemContent,
      }}
    />
    <div className="campaigns-controls">
      <Button
        type="button"
        basic
        icon="refresh"
        content="Обновить список"
        size="small"
        onClick={refreshCampaigns}
      />
    </div>
    {error && <ErrorMessage error={error} padded="top" />}
  </CampaignsWrap>
)

Campaigns.defaultProps = {
  error: null,
}

Campaigns.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCampaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  refreshCampaigns: PropTypes.func.isRequired,
  error: PropTypes.string,
  projectId: PropTypes.string.isRequired,
}

export default Campaigns
