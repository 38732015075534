import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import * as adjStatuses from '../../../../../constants/adj-statuses'
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED } from '../../../../../styles/variables'

const StyledAdd = styled.span`
  color: ${COLOR_GREEN};
`

const StyledDelete = styled.span`
  color: ${COLOR_RED};
`

const StyledOptimization = styled.span`
  color: ${COLOR_BLUE};
`

const OperationTitle = ({ adjStatus }) => {
  const operation = adjStatuses.getOperation(adjStatus)
  const title = adjStatuses.translateOperation(operation)
  switch (operation) {
    case adjStatuses.OPERATION_ADD:
      return <StyledAdd>{title}</StyledAdd>
    case adjStatuses.OPERATION_DELETE:
      return <StyledDelete>{title}</StyledDelete>
    case adjStatuses.OPERATION_OPTIMIZATION:
      return <StyledOptimization>{title}</StyledOptimization>
    // no default
  }
  return 'n/a'
}

OperationTitle.propTypes = {
  adjStatus: PropTypes.oneOf(adjStatuses.getStatuses()).isRequired,
}

export default OperationTitle
