import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { Message, Icon } from 'semantic-ui-react'
import { getProject, isProjectFetching } from '@airmedia/projects-embed'

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const project = getProject(state, projectId)

  return {
    projectId,
    isNotConnectedToYandex: (project && project.yandexDirectStatus) !== 'connected' || false,
    isProjectFetching: isProjectFetching(state, projectId),
  }
}

const MessageWrap = styled.div`
  margin-top: 10px;
  text-align: center;
`

const YandexNotAvailableMessage = ({ projectId, isNotConnectedToYandex, isProjectFetching }) => {
  if (projectId && !isProjectFetching && isNotConnectedToYandex) {
    return (
      <MessageWrap>
        <Message warning compact>
          <Message.Content>
            <Icon name="warning circle" />
            Работа с Яндекс.Директом в настоящий момент невозможна. Подключить рекламную систему
            можно на странице с настройками проекта.
          </Message.Content>
        </Message>
      </MessageWrap>
    )
  }

  return null
}

YandexNotAvailableMessage.defaultProps = {
  projectId: null,
  isProjectFetching: null,
}

YandexNotAvailableMessage.propTypes = {
  projectId: PropTypes.string,
  isNotConnectedToYandex: PropTypes.bool.isRequired,
  isProjectFetching: PropTypes.bool,
}

export default connect(mapStateToProps)(YandexNotAvailableMessage)
