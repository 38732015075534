export const PENDING = 'pending'
export const ADJUSTING = 'adjusting'
export const ADJUST_FAILED = 'adjust_failed'
export const ADJUSTED = 'adjusted'
export const SYNC_PENDING = 'sync_pending'
export const SYNCING = 'syncing'
export const SYNC_FAILED = 'sync_failed'
export const COMPLETED = 'completed'
export const CANCELED = 'canceled'

export const translate = (status) => {
  switch (status) {
    case PENDING:
      return 'Задача в очереди, ожидает выполения.'
    case ADJUSTING:
      return 'Выполняется операция корректировки минус-фраз...'
    case ADJUST_FAILED:
      return 'Возникла ошибка при выполении задачи.'
    case ADJUSTED:
      return 'Корректировка выполнена. (требуется модерация)'
    case SYNC_PENDING:
      return 'Задача в очереди для выгрузки изменений в Яндекс.Директ.'
    case SYNCING:
      return 'Выполняется выгрузка изменений в Яндекс.Директ...'
    case SYNC_FAILED:
      return 'Возникла ошибка при выгрузке изменений.'
    case COMPLETED:
      return 'Задача завершена — изменения выгружены в Яндекс.Директ.'
    case CANCELED:
      return 'Задача отменена.'
    // no default
  }

  return status
}
