import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchProjectDetails, getProject } from '@airmedia/projects-embed'
import { RequestError } from 'pannier'
import TasksListPage from './components/TasksListPage'
import {
  resetTasksState,
  fetchTasks,
  getTasks,
  getOffset,
  getTotal,
  isFetching,
  FETCH_LIST_FAILURE,
} from '../../stores/tasks'
import withInfiniteScroll from '../../utils/withInfiniteScroll'
import { TASKS_LIST_PAGE_SIZE } from '../../constants/page-sizes'

const TasksListPageWithInfiniteScroll = withInfiniteScroll(TasksListPage)

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => ({
  tasks: getTasks(state),
  offset: getOffset(state),
  total: getTotal(state),
  isFetching: isFetching(state),
  project: getProject(state, projectId),
})

class TasksListPageContainer extends Component {
  componentDidMount() {
    const {
      project,
      match: {
        params: { projectId },
      },
    } = this.props

    if (project && !project.userRole) {
      this.props.fetchProjectDetails(projectId)
    }

    this.props.fetchTasks(projectId)
  }

  componentWillUnmount() {
    this.props.resetTasksState()
  }

  fetchData = () => {
    const {
      fetchTasks,
      match: {
        params: { projectId },
      },
      offset,
    } = this.props

    fetchTasks(projectId, TASKS_LIST_PAGE_SIZE, offset + TASKS_LIST_PAGE_SIZE)
  }

  render() {
    const {
      tasks,
      isFetching,
      total,
      match: {
        params: { projectId },
      },
    } = this.props
    const shouldFetch = !isFetching && tasks.length < total

    return (
      <RequestError types={[FETCH_LIST_FAILURE]}>
        <TasksListPageWithInfiniteScroll
          fetchData={this.fetchData}
          shouldFetch={shouldFetch}
          projectId={projectId}
          tasks={tasks}
          isFetching={isFetching}
        />
      </RequestError>
    )
  }
}

TasksListPageContainer.defaultProps = {
  project: null,
}

TasksListPageContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  resetTasksState: PropTypes.func.isRequired,
  fetchProjectDetails: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  project: PropTypes.shape({
    userRole: PropTypes.string,
  }),
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, { resetTasksState, fetchProjectDetails, fetchTasks })(
  TasksListPageContainer
)
