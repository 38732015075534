import React from 'react'
import PropTypes from 'prop-types'
import { makeTrans } from 'pannier'
import * as taskStatuses from '../../../../../constants/task-statuses'

const getMessage = makeTrans({
  adjusting: ({ pluralForm, count, completedCount }) =>
    pluralForm === 0
      ? `Корректировка выполнена для ${completedCount} из ${count} кампании.`
      : `Корректировка выполнена для ${completedCount} из ${count} кампаний.`,
  adjust_with_errors: ({ pluralForm, count }) =>
    pluralForm === 0
      ? `Не удалось выполнить корректировку для ${count} кампании.`
      : `Не удалось выполнить корректировку для ${count} кампаний.`,
  adjust_completed: ({ pluralForm, count }) =>
    pluralForm === 0
      ? `Корректировка успешно выполнена для ${count} кампании.`
      : `Корректировка успешно выполнена для ${count} кампаний.`,
  syncing: ({ pluralForm, count, completedCount }) =>
    pluralForm === 0
      ? `Синхронизировано ${completedCount} из ${count} кампании.`
      : `Синхронизировано ${completedCount} из ${count} кампаний.`,
})

const Description = ({ task }) => {
  if (!task.campaignStats) {
    return null
  }

  const { campaignStats, status } = task

  if (status === taskStatuses.ADJUSTING) {
    return getMessage('adjusting', {
      count: campaignStats.total,
      completedCount: campaignStats.adjustCompletedCount,
    })
  }

  if (status === taskStatuses.ADJUSTED || status === taskStatuses.SYNC_PENDING) {
    if (campaignStats.adjustFailedCount > 0) {
      return getMessage('adjust_with_errors', {
        count: campaignStats.adjustFailedCount,
      })
    }

    return getMessage('adjust_completed', {
      count: campaignStats.adjustCompletedCount,
    })
  }

  if (status === taskStatuses.SYNCING) {
    return getMessage('syncing', {
      count: campaignStats.total,
      completedCount: campaignStats.syncCompletedCount,
    })
  }

  if (status === taskStatuses.COMPLETED) {
    const commonDescription = getMessage('syncing', {
      count: campaignStats.total,
      completedCount: campaignStats.syncCompletedCount,
    })

    if (campaignStats.syncFailedCount < 1) {
      return commonDescription
    }

    return (
      <>
        {commonDescription} Конфликтов: <strong>{campaignStats.syncFailedCount}</strong>
      </>
    )
  }

  return null
}

Description.propTypes = {
  task: PropTypes.shape({
    status: PropTypes.string.isRequired,
    campaignStats: PropTypes.shape({
      total: PropTypes.number.isRequired,
      adjustCompletedCount: PropTypes.number.isRequired,
      adjustFailedCount: PropTypes.number.isRequired,
      syncCompletedCount: PropTypes.number.isRequired,
      syncFailedCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default Description
