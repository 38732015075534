import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import TasksListItem from './TasksListItem'

const TasksList = ({ tasks }) => (
  <List divided selection>
    {tasks.map((task) => (
      <TasksListItem key={task.id} task={task} />
    ))}
  </List>
)

TasksList.defaultProps = {
  tasks: null,
}

TasksList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    })
  ),
}

export default TasksList
