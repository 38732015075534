import isObject from 'lodash/isObject'
import omit from 'lodash/omit'
import rollbar from '../rollbar'

const logError = (err, context = {}) => {
  const message = context.message || err.message || 'Uncaught error'
  let ctx = omit(context, 'message')

  if (err.name === 'ApiError' && err.respStatus !== 422) {
    ctx = {
      respStatus: err.status,
      respBody: isObject(err.response) ? err.response : null,
      ...ctx,
    }
  }

  return rollbar.error(message, err, ctx)
}

export default logError
