export const UNCHANGED = 'unchanged'
export const ADDED = 'added'
export const DELETED = 'deleted'
export const CONFLICT = 'conflict'
export const OMITTED = 'omitted'
export const EXISTS = 'exists'
export const NOT_FOUND = 'not_found'
export const USELESS = 'useless'
export const INFREQUENT = 'infrequent'
export const DISPLACED = 'displaced'
export const ABSORBED = 'absorbed'

export const OPERATION_UNKNOWN = 0
export const OPERATION_ADD = 1
export const OPERATION_DELETE = 2
export const OPERATION_OPTIMIZATION = 3

export const getStatuses = () => [
  UNCHANGED,
  ADDED,
  DELETED,
  CONFLICT,
  OMITTED,
  USELESS,
  INFREQUENT,
  DISPLACED,
  EXISTS,
  NOT_FOUND,
  ABSORBED,
]

export const translate = (status) => {
  switch (status) {
    case UNCHANGED:
      return 'без изменений'
    case ADDED:
      return 'добавлено'
    case DELETED:
      return 'удалено'
    case CONFLICT:
      return 'конфликт'
    case OMITTED:
      return 'переполнено'
    case EXISTS:
      return 'добавлено ранее'
    case NOT_FOUND:
      return 'не найдено'
    case USELESS:
      return 'отклонено по частотности'
    case INFREQUENT:
      return 'удалено по частотности'
    case DISPLACED:
      return 'вытеснено'
    case ABSORBED:
      return 'пересечение с ключевой фразой'
    // no default
  }

  return status
}

export const isAnomaly = (status) =>
  ![UNCHANGED, ADDED, DELETED, INFREQUENT, ABSORBED].includes(status)

export const getOperation = (status) => {
  switch (status) {
    case ADDED:
    case EXISTS:
    case OMITTED:
    case USELESS:
      return OPERATION_ADD
    case DELETED:
    case NOT_FOUND:
      return OPERATION_DELETE
    case DISPLACED:
    case INFREQUENT:
    case ABSORBED:
      return OPERATION_OPTIMIZATION
    default:
      return OPERATION_UNKNOWN
  }
}

export const translateOperation = (operation) => {
  switch (operation) {
    case OPERATION_ADD:
      return 'добавление'
    case OPERATION_DELETE:
      return 'удаление'
    case OPERATION_OPTIMIZATION:
      return 'оптимизация списка'
    default:
      return 'n/a'
  }
}
