import { createSelector } from 'reselect'

const getYandexCampaignsIds = (state) => state.yandexCampaigns.ids

const getYandexCampaignsById = (state) => state.yandexCampaigns.byId

export const isFetching = (state) => state.yandexCampaigns.isFetching

export const getYandexCampaigns = createSelector(
  getYandexCampaignsById,
  getYandexCampaignsIds,
  (items, ids) => ids.map((id) => items[id])
)

export const getYandexCampaign = createSelector(
  getYandexCampaignsById,
  (state, id) => id,
  (items, id) => items[id]
)
