import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { Container, Grid } from 'semantic-ui-react'
import { ProjectsList } from '@airmedia/projects-embed'
import { resetYandexCampaignsState } from '../../stores/yandexCampaigns'

const IndexPageWrap = styled.div`
  padding-top: 50px;
`

class IndexPage extends Component {
  componentDidMount() {
    this.props.resetYandexCampaignsState()
  }

  render() {
    return (
      <IndexPageWrap>
        <Container>
          <Grid columns={1} centered>
            <Grid.Row>
              <Grid.Column width={8}>
                <ProjectsList />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </IndexPageWrap>
    )
  }
}

IndexPage.propTypes = {
  resetYandexCampaignsState: PropTypes.func.isRequired,
}

export default connect(null, { resetYandexCampaignsState })(IndexPage)
