import { getJSON, RSAA } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { camelizeKeys, decamelizeKeys } from 'humps'
import qs from 'query-string'
import { withErrorNotification } from 'pannier'
import * as types from './constants'
import * as requestHeaders from '../../constants/request-headers'
import { isCreating, isFetching, isTaskFetching, isTaskDeleting, isTaskSyncing } from './selectors'
import { TASK, TASK_ARRAY } from '../schemas'
import { TASKS_LIST_PAGE_SIZE } from '../../constants/page-sizes'
import getMessage from '../../messages'

export const resetTasksState = () => ({
  type: types.RESET_STATE,
})

export const setReportFilter = (filter) => ({
  type: types.SET_REPORT_FILTER,
  filter,
})

export const setReportLayout = (layout) => ({
  type: types.SET_REPORT_LAYOUT,
  layout,
})

// async actions

export const fetchTasks = (projectId, limit = TASKS_LIST_PAGE_SIZE, offset = 0) => {
  const query = qs.stringify({
    limit,
    offset,
  })

  return {
    [RSAA]: {
      endpoint: `/api/${projectId}/tasks?${query}`,
      method: 'GET',
      credentials: 'same-origin',
      headers: requestHeaders.GET,
      options: {
        authRequired: true,
      },
      bailout: (state) => isFetching(state),
      types: [
        {
          type: types.FETCH_LIST_REQUEST,
          meta: {
            offset,
          },
        },
        {
          type: types.FETCH_LIST_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res.clone()).then((json) => normalize(json.data, TASK_ARRAY)),
          meta: (action, state, res) =>
            getJSON(res.clone()).then((json) => camelizeKeys(json.meta)),
        },
        types.FETCH_LIST_FAILURE,
      ],
    },
  }
}

export const fetchTaskDetails = (projectId, taskId) => ({
  [RSAA]: {
    endpoint: `/api/${projectId}/tasks/${taskId}`,
    method: 'GET',
    credentials: 'same-origin',
    headers: requestHeaders.GET,
    options: {
      authRequired: true,
    },
    bailout: (state) => isTaskFetching(state, taskId),
    types: [
      {
        type: types.FETCH_DETAILS_REQUEST,
        meta: {
          taskId,
        },
      },
      {
        type: types.FETCH_DETAILS_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res.clone()).then((json) => normalize(json.data, TASK)),
        meta: {
          taskId,
        },
      },
      {
        type: types.FETCH_DETAILS_FAILURE,
        meta: {
          taskId,
        },
      },
    ],
  },
})

export const createTask = (projectId, data, removeDuplicates, callback) => (dispatch) =>
  dispatch({
    [RSAA]: {
      endpoint: `/api/${projectId}/tasks${removeDuplicates ? '?remove_duplicates=true' : ''}`,
      method: 'POST',
      credentials: 'same-origin',
      headers: requestHeaders.POST,
      options: {
        authRequired: true,
      },
      bailout: (state) => isCreating(state),
      body: JSON.stringify({
        data: decamelizeKeys(data),
      }),
      types: [
        types.CREATE_REQUEST,
        {
          type: types.CREATE_SUCCESS,
          payload: (action, state, res) =>
            getJSON(res.clone()).then((json) => normalize(json.data, TASK)),
        },
        {
          type: types.CREATE_FAILURE,
          meta: {
            data,
          },
        },
      ],
    },
  }).then((action) => {
    if (action && action.payload && action.type === types.CREATE_SUCCESS && callback) {
      callback(action.payload.result)
    }

    return action
  })

export const deleteTask = (projectId, taskId) => ({
  [RSAA]: {
    endpoint: `/api/${projectId}/tasks/${taskId}`,
    method: 'DELETE',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: (state) => isTaskDeleting(state, taskId),
    types: [types.DELETE_REQUEST, types.DELETE_SUCCESS, types.DELETE_FAILURE].map((type) => ({
      type,
      meta: { taskId },
    })),
  },
})

export const deleteTaskWithNotification = withErrorNotification(deleteTask, {
  header: 'Удаление задачи',
  content: getMessage('notifications.delete_task'),
})

export const syncTask = (projectId, taskId) => ({
  [RSAA]: {
    endpoint: `/api/${projectId}/tasks/${taskId}/sync`,
    method: 'PUT',
    credentials: 'same-origin',
    options: {
      authRequired: true,
    },
    bailout: (state) => isTaskSyncing(state, taskId),
    types: [
      {
        type: types.SYNC_REQUEST,
        meta: {
          taskId,
        },
      },
      {
        type: types.SYNC_SUCCESS,
        meta: {
          taskId,
        },
      },
      {
        type: types.SYNC_FAILURE,
        meta: {
          taskId,
        },
      },
    ],
  },
})
