import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { makeRequestErrors, notifications } from 'pannier'
import { projectReducers } from '@airmedia/projects-embed'
import user, { LOGOUT } from './user'
import tasks, { RESET_STATE as RESET_TASKS_STATE } from './tasks'
import yandexCampaigns, {
  RESET_STATE as RESET_YANDEX_CAMPAIGNS_STATE,
  FETCH_LIST_SUCCESS as YANDEX_CAMPAIGNS_FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE as YANDEX_CAMPAIGNS_FETCH_LIST_FAILURE,
} from './yandexCampaigns'

const requestErrors = makeRequestErrors({
  [YANDEX_CAMPAIGNS_FETCH_LIST_SUCCESS]: YANDEX_CAMPAIGNS_FETCH_LIST_FAILURE,
})

const appReducer = combineReducers({
  user,
  projects: projectReducers,
  tasks,
  yandexCampaigns,
  requestErrors,
  notifications,
  form: formReducer,
})

const resetState = (state, name) => ({
  ...state,
  [name]: undefined,
})

const getNewState = (state, action) => {
  switch (action.type) {
    case LOGOUT:
      return undefined
    case RESET_TASKS_STATE:
      return resetState(state, 'tasks')
    case RESET_YANDEX_CAMPAIGNS_STATE:
      return resetState(state, 'yandexCampaigns')
    default:
      return state
  }
}

const rootReducer = (state, action) => {
  const newState = getNewState(state, action)

  return appReducer(newState, action)
}

export default rootReducer
