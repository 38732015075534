import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Table } from 'semantic-ui-react'
import OperationTitle from './OperationTitle'
import CopyButton from '../../../../components/CopyButton'
import { translate } from '../../../../../constants/adj-statuses'

const StyledTable = styled(Table)`
  .border-left-fix {
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  }

  .copy-block {
    display: flex;

    *:last-child .icon {
      color: #ee7b00;
      opacity: 0.7;
    }
  }
`

const COPY_BUTTON_TOOLTIP_TEXT = 'Скопировать номера кампаний в буфер обмена'
const COPY_BUTTON_TOOLTIP_TEXT_DIRECT = 'Скопировать как фильтр для Директ Коммандера'

const safeJoin = (arr, sep) => (Array.isArray(arr) ? arr.join(sep || ', ') : arr)
const joinComma = (arr) => safeJoin(arr, ', ')
const firstItem = (items) => items[0]

const GroupedDataTable = ({ items }) => (
  <StyledTable celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={2}>Задание</Table.HeaderCell>
        <Table.HeaderCell width={3}>№№ Кампаний</Table.HeaderCell>
        <Table.HeaderCell width={3}>Статус</Table.HeaderCell>
        <Table.HeaderCell width={4}>Минус-фраза</Table.HeaderCell>
        <Table.HeaderCell width={4}>
          Соответствие с единым списком минус-фраз / ключевой фразой
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {items.map(({ campaignId, adjStatus, items }) => (
        <Fragment key={`${campaignId}-${adjStatus}-${items.keyword}`}>
          <Table.Row>
            <Table.Cell verticalAlign="top" rowSpan={items.length}>
              <OperationTitle adjStatus={adjStatus} />
            </Table.Cell>
            <Table.Cell verticalAlign="top" rowSpan={items.length}>
              {joinComma(campaignId)}
              <div className="copy-block">
                <CopyButton
                  text={safeJoin(campaignId, ' ')}
                  tooltipText={COPY_BUTTON_TOOLTIP_TEXT}
                />
                <CopyButton
                  text={`= Номер = [${safeJoin(campaignId, ' ')}]`}
                  iconName="copy outline"
                  tooltipText={COPY_BUTTON_TOOLTIP_TEXT_DIRECT}
                />
              </div>
            </Table.Cell>
            <Table.Cell verticalAlign="top" rowSpan={items.length}>
              {translate(adjStatus)}
            </Table.Cell>
            <Table.Cell verticalAlign="top">{firstItem(items).keyword}</Table.Cell>
            <Table.Cell verticalAlign="top">{joinComma(firstItem(items).matches)}</Table.Cell>
          </Table.Row>
          {items.slice(1).map(({ keyword, matches }) => (
            <Table.Row key={`${campaignId}-${adjStatus}-${items.keyword}-${keyword}-${matches}`}>
              <Table.Cell width={4} verticalAlign="top" className="border-left-fix">
                {keyword}
              </Table.Cell>
              <Table.Cell width={6} verticalAlign="top">
                {joinComma(matches)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Fragment>
      ))}
    </Table.Body>
  </StyledTable>
)

GroupedDataTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
        .isRequired,
      adjStatus: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          keyword: PropTypes.string.isRequired,
          matches: PropTypes.arrayOf(PropTypes.string),
        })
      ).isRequired,
    })
  ).isRequired,
}

export default GroupedDataTable
