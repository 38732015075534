import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import { makeBooleanFlagReducer } from '../makeReducer'

export * from './actions'
export * from './selectors'
export * from './constants'

export default combineReducers({
  login: handleActions(
    {
      [types.FETCH_PROFILE_SUCCESS]: (state, action) => action.payload.email,
    },
    null
  ),
  isFetching: makeBooleanFlagReducer(
    types.FETCH_PROFILE_REQUEST,
    types.FETCH_PROFILE_SUCCESS,
    types.FETCH_PROFILE_FAILURE
  ),
})
