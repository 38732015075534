import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { AutoSizer, Table, Column } from 'react-virtualized'
import OperationTitle from './OperationTitle'
import { translate } from '../../../../../constants/adj-statuses'

const joinComma = (values) => (Array.isArray(values) ? values.join(', ') : values)

const borderInner = '1px solid rgba(34, 36, 38, 0.1)'
const borderOuter = '1px solid rgba(34, 36, 38, 0.15)'

const Wrapper = styled.div`
  height: calc(100vh - 330px);
  margin: 1em 0;

  .ReactVirtualized__Table__Grid {
    border-top: none;
    border-radius: 0 0 3px 3px;

    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow,
  .ReactVirtualized__Table__row {
    text-transform: none;
    background: white;
  }

  .ReactVirtualized__Table__headerRow {
    border: ${borderOuter};
    border-radius: 3px 3px 0 0;
    background: #f9fafb;
  }

  .ReactVirtualized__Table__row {
    border-left: ${borderOuter};
    border-right: ${borderOuter};
    border-bottom: ${borderInner};

    &:last-child {
      border-bottom: ${borderOuter};
      border-radius: 0 0 3px 3px;
    }
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    height: 100%;
    display: flex;
    align-items: center;
  }
`

const ListDataTable = ({ items }) => (
  <Wrapper>
    <AutoSizer>
      {({ width, height }) => (
        <Table
          height={height}
          width={width}
          headerHeight={40}
          rowHeight={30}
          rowCount={items.length}
          rowGetter={({ index }) => ({ ...items[index], idx: index })}
        >
          <Column
            width={width * (2 / 16)}
            dataKey="adjStatus"
            label="Задание"
            cellRenderer={({ cellData }) => <OperationTitle adjStatus={cellData} />}
          />
          <Column width={width * (2 / 16)} dataKey="campaignId" label="№ Кампании" />
          <Column width={width * (4 / 16)} dataKey="keyword" label="Минус-фраза" />
          <Column
            width={width * (3 / 16)}
            dataKey="adjStatus"
            label="Статус"
            cellRenderer={({ cellData }) => translate(cellData)}
          />
          <Column
            width={width * (5 / 16)}
            dataKey="matches"
            label="Соответствие с единым списком минус-фраз / ключевой фразой"
            cellRenderer={({ cellData }) => joinComma(cellData)}
          />
        </Table>
      )}
    </AutoSizer>
  </Wrapper>
)

ListDataTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      campaignId: PropTypes.number.isRequired,
      adjStatus: PropTypes.string.isRequired,
      keyword: PropTypes.string.isRequired,
      matches: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
}

export default ListDataTable
