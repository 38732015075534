import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { camelizeKeys } from 'humps'
import * as types from './constants'
import * as requestHeaders from '../../constants/request-headers'
import { isFetching } from './selectors'
import { YANDEX_CAMPAIGNS_ARRAY } from './schemas'

export const resetYandexCampaignsState = () => ({
  type: types.RESET_STATE,
})

// async actions

export const fetchYandexCampaigns = (projectId) => ({
  [RSAA]: {
    endpoint: `/api/${projectId}/yandex/campaigns`,
    method: 'GET',
    credentials: 'same-origin',
    headers: requestHeaders.GET,
    options: {
      authRequired: true,
    },
    bailout: (state) => isFetching(state),
    types: [
      types.FETCH_LIST_REQUEST,
      {
        type: types.FETCH_LIST_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res.clone()).then((json) => normalize(json.data, YANDEX_CAMPAIGNS_ARRAY)),
        meta: (action, state, res) => getJSON(res.clone()).then((json) => camelizeKeys(json.meta)),
      },
      {
        type: types.FETCH_LIST_FAILURE,
        meta: {
          projectId,
        },
      },
    ],
  },
})
