import { SubmissionError } from 'redux-form'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import chunk from 'lodash/chunk'
import { camelize, decamelizeKeys } from 'humps'
import getMessage from '../messages'
import pointerToFormPath from './pointerToFormPath'
import pointerToMessageKey from './pointerToMessageKey'
import setPathValue from './setPathValue'
import logError from './logError'

const isError = (action) => action && action.error

const getServerErrors = (payload) => {
  if (payload && payload.response && payload.status === 422) {
    return payload.response.errors || []
  }
  return []
}

const getFormSubmitMessage = (payload) => {
  if (payload && payload.status) {
    if (payload.status >= 400 && payload.status < 500) {
      return getMessage('new_task_form.error.4xx', { status: payload.status })
    }

    if (payload.status >= 500) {
      return getMessage('new_task_form.error.5xx', { status: payload.status })
    }
  }

  return getMessage('form.submit.unk_error')
}

const isMappablePath = (path, errorMapping) => {
  if (!path) {
    return false
  }

  return errorMapping.some((pattern) => {
    if (pattern instanceof RegExp) {
      return pattern.test(path)
    }
    return pattern === path
  })
}

export const getCampaignIds = (campaigns) => (campaigns && campaigns.map((item) => item.id)) || []
export const splitByLineBreak = (str) =>
  str
    ? uniq(
        str
          .split('\n')
          .map((w) =>
            w
              .trim()
              .replace(/\s{2,}/g, ' ')
              .toLowerCase()
          )
          .filter((w) => w)
      )
    : []

export const validate = ({ campaigns, addKeywords, subtractKeywords }) => {
  const errors = {}

  if (!campaigns || !campaigns.length) {
    errors.campaigns = {
      _error: getMessage('new_task_form.campaigns.min_items'),
    }
  }

  if (!addKeywords && !subtractKeywords) {
    errors.addKeywords = {
      _error: [getMessage('new_task_form.keywords.required')],
    }
  }

  return errors
}

export const getSubmitError = (error, submitFailed) => {
  if (error) {
    return error
  }

  return submitFailed ? getMessage('form.submit.error') : null
}

export const prepareDuplicatesForMessage = (value, duplicates, data) =>
  [value, ...duplicates.map((val) => get(data, camelize(val.slice(5))))].sort((a, b) =>
    a.localeCompare(b)
  )

export const makeHandleResponse = (formName, errorMapping) => (action) => {
  if (!isError(action)) {
    return action
  }

  const serverError = getServerErrors(action.payload)

  if (!serverError.length) {
    logError(action.payload, {
      message: `Form ${formName} submit failed.`,
    })

    throw new SubmissionError({
      _error: getFormSubmitMessage(action.payload),
    })
  }

  const unmappedErrors = []
  const formErrors = {}

  serverError.forEach((err) => {
    let formPath = pointerToFormPath(err.property)

    if (isMappablePath(formPath, errorMapping)) {
      const messageKey = pointerToMessageKey(err.property, formName, err.code)
      const messageValue = get(decamelizeKeys(action.meta), err.property)
      const messageOptions = {
        value: messageValue,
        ...err.params,
      }

      if (err.code === 'duplicate' && err.params && Array.isArray(err.params.with)) {
        const data = action.meta && action.meta.data

        messageOptions.duplicates = prepareDuplicatesForMessage(messageValue, err.params.with, data)
      }

      const message = getMessage(messageKey, messageOptions)

      if (/campaigns/.test(formPath)) {
        formPath = formPath.replace(/\[\d+]$/, '')
      }

      setPathValue(formErrors, formPath, message)
    } else {
      unmappedErrors.push(err)
    }
  })

  logError(action.payload, {
    message: `Form ${formName} submit failed.`,
    unmappedErrors,
  })

  throw new SubmissionError({
    _error: getMessage(unmappedErrors.length ? 'form.submit.unk_error' : 'form.submit.error'),
    ...formErrors,
  })
}

export const createChunks = (data, chunkSize) => {
  const { campaigns } = data
  const campaignsChunks = chunk(campaigns, chunkSize)
  const currentDate = new Date().toLocaleDateString()

  return campaignsChunks.map((chunk, idx) => {
    const rangeStart = `${chunkSize * idx + 1}`
    const rangeEnd = `${chunkSize * idx + (chunk.length === chunkSize ? chunkSize : chunk.length)}`
    const range = rangeStart !== rangeEnd ? `${rangeStart}-${rangeEnd}` : rangeStart
    let name

    if (data.name) {
      name = `${data.name} (${range})`
    } else {
      name = `Корректировка от ${currentDate} (${range})`
    }

    return {
      ...data,
      name,
      campaigns: chunk,
    }
  })
}
