import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import * as types from './constants'
import * as taskStatuses from '../../constants/task-statuses'
import * as adjLevelFilters from '../../constants/adj-level-filters'
import * as reportLayouts from '../../constants/report-layout'
import {
  makeMergeEntities,
  makeUpdateEntity,
  makeRemoveEntity,
  makeRemoveId,
  mergeIds,
  resetEntities,
  resetIds,
} from '../actionHandlers'
import { makeBooleanFlagReducer } from '../makeReducer'

export * from './actions'
export * from './selectors'
export * from './constants'

const getTaskId = (action) => action.meta.taskId
const makeUpdateTask = (data) => makeUpdateEntity(getTaskId, data)

export default combineReducers({
  byId: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: resetEntities,
      [types.FETCH_LIST_SUCCESS]: makeMergeEntities('tasks'),
      [types.FETCH_DETAILS_REQUEST]: makeUpdateTask({ isFetching: true }),
      [types.FETCH_DETAILS_SUCCESS]: makeUpdateTask((action) => ({
        ...action.payload.entities.tasks[getTaskId(action)],
        isFetching: false,
      })),
      [types.FETCH_DETAILS_FAILURE]: makeUpdateTask({ isFetching: false }),
      [types.CREATE_SUCCESS]: makeMergeEntities('tasks'),
      [types.DELETE_REQUEST]: makeUpdateTask({ isDeleting: true }),
      [types.DELETE_SUCCESS]: makeRemoveEntity(getTaskId),
      [types.DELETE_FAILURE]: makeUpdateTask({ isDeleting: false }),
      [types.SYNC_REQUEST]: makeUpdateTask({ isSyncing: true }),
      [types.SYNC_SUCCESS]: makeUpdateTask({
        status: taskStatuses.SYNC_PENDING,
        isSyncing: false,
      }),
      [types.SYNC_FAILURE]: makeUpdateTask({ isSyncing: false }),
    },
    {}
  ),
  ids: handleActions(
    {
      [types.FETCH_LIST_REQUEST]: resetIds,
      [types.FETCH_LIST_SUCCESS]: mergeIds,
      [types.DELETE_SUCCESS]: makeRemoveId(getTaskId),
    },
    []
  ),
  offset: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.meta.offset,
    },
    0
  ),
  total: handleActions(
    {
      [types.FETCH_LIST_SUCCESS]: (state, action) => action.meta.total,
    },
    0
  ),
  isCreating: makeBooleanFlagReducer(
    types.CREATE_REQUEST,
    types.CREATE_SUCCESS,
    types.CREATE_FAILURE
  ),
  isFetching: makeBooleanFlagReducer(
    types.FETCH_LIST_REQUEST,
    types.FETCH_LIST_SUCCESS,
    types.FETCH_LIST_FAILURE
  ),
  reportFilter: handleActions(
    {
      [types.SET_REPORT_FILTER]: (state, action) => action.filter,
    },
    adjLevelFilters.ALL
  ),
  reportLayout: handleActions(
    {
      [types.SET_REPORT_LAYOUT]: (state, action) => action.layout,
    },
    reportLayouts.GROUPED
  ),
})
