import { isAuth, getProfile } from 'auth-component'
import * as types from './constants'

export const logout = () => ({
  type: types.LOGOUT,
})

export const fetchProfile = () => (dispatch) => {
  if (!isAuth()) {
    return null
  }

  dispatch({ type: types.FETCH_PROFILE_REQUEST })
  return getProfile()
    .then((payload) => dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload }))
    .catch((error) => dispatch({ type: types.FETCH_PROFILE_FAILURE, error }))
}
